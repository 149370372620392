/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const constants = {
  SUCCESS_MIN: 75,
  WARNING_MIN: 50,
  ERROR_MIN: 0,
  COMPLETE: 100,
  OPTIONAL: 50,
  REQUIRED: 0,
};

function useStatistics() {
  const { user } = useSelector((state) => state);
  const { COMPLETE, OPTIONAL, REQUIRED } = constants;
  // =========== START ACCOUNT STATISTICS ==================
  const profileStatistics = useMemo(() => {
    const { full_name, profile_image, job, about } = user;
    let local = {
      items: [
        { name: 'Full Name', score: full_name ? COMPLETE : REQUIRED },
        { name: 'Specialization', score: job ? COMPLETE : OPTIONAL },
        { name: 'Bio', score: about ? COMPLETE : OPTIONAL },
        { name: 'Image', score: profile_image ? COMPLETE : OPTIONAL },
      ],
    };
    local.averageScore =
      local.items.reduce((accumulator, item) => accumulator + item.score, 0) /
      local.items.length;
    return local;
  }, [user]);
  // =========== END ACCOUNT STATISTICS==================

  // =========== START PROJECTS STATISTICS==================
  const projectsStatistics = useMemo(() => {
    let local = {
      total: user.projects.length,
      totalActive: 0,
      totalImages: 0,
      skills: {},
    };
    user.projects.forEach((project) => {
      local.totalImages += project.images.length;
      if (project.active) {
        local.totalActive += 1;
      }
      project.skills.forEach(({ skill }) => {
        if (local.skills[skill]) {
          local.skills[skill] += 1;
        } else {
          local.skills[skill] = 1;
        }
      });
    });
    return local;
  }, [user]);
  // =========== END PROJECTS STATISTICS==================

  // =========== START PROSPECTS STATISTICS==================
  const prospectsStatistics = useMemo(() => {
    let local = {
      total: user.prospects.length,
      totalActive: 0,
      skills: {},
    };
    user.prospects.forEach((prospect) => {
      if (prospect.active) {
        local.totalActive += 1;
      }
      prospect.skills.forEach(({ skill }) => {
        if (local.skills[skill]) {
          local.skills[skill] += 1;
        } else {
          local.skills[skill] = 1;
        }
      });
    });
    return local;
  }, [user]);

  return {
    constants,
    profileStatistics,
    projectsStatistics,
    prospectsStatistics,
  };
}
// =========== END PROJECTS STATISTICS==================

export default useStatistics;
