/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { TypeOneSkillTag } from '../../../Skill';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
const Skills = ({ skills }) => {
  return (
    <Container>
      {skills?.length
        ? skills.map(({ skill, id, active }) => {
            return (
              <TypeOneSkillTag key={id} name={skill} id={id} active={active} />
            );
          })
        : ''}
    </Container>
  );
};

Skills.propTypes = {
  skills: PropTypes.array,
};

export default Skills;
