import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Widget } from 'components';
import { useStatistics } from 'hooks';
import { HiShare } from 'react-icons/hi';
import { Stacked } from 'components/charts';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  svg {
    color: green;
    margin-bottom: -3px;
    margin-right: -2px;
  }
`;

function Prospects({ link }) {
  const { prospectsStatistics, constants } = useStatistics();
  const { COMPLETE, OPTIONAL } = constants;
  const { total, totalActive, skills } = prospectsStatistics;

  const projectsData = useMemo(() => {
    return [
      {
        name: 'Prospects',
        active: totalActive || 0,
        inactive: total - totalActive || 0,
      },
    ];
  }, [total, totalActive]);
  return (
    <Widget
      title="Prospects"
      link={link}
      score={totalActive > 0 ? COMPLETE : OPTIONAL}
      icon={<HiShare size="30px" />}
    >
      <Body>
        <Stacked data={projectsData} />
        <span>
          Total Distinct Skills: <strong>{Object.keys(skills).length}</strong>
        </span>
      </Body>
    </Widget>
  );
}

Prospects.propTypes = {
  link: PropTypes.string,
};
export default Prospects;
