import styled from 'styled-components';

export default styled.div`
  position: relative;
  max-width: 1400px;
  min-width: 330px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media only screen and (max-width: 1432px) {
    padding: 0 16px;
    width: calc(100% - 32px);
  }
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    padding: 0 8px;
    width: calc(100% - 16px);
  }
`;
