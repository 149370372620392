/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 180px;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  background: ${({ theme }) => theme.colors.general.light};
`;
const Img = styled.img`
  height: auto;
  width: 176px;
  object-fit: fit;
`;

const Image = ({ image, alt }) => {
  return (
    <Container>
      <Img src={image} alt={alt} />
    </Container>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;
