/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BsCheckLg } from 'react-icons/bs';
import styled from 'styled-components';
import * as Yup from 'yup';

const CustomForm = styled(Form)`
  display: flex;
`;

const SuccessSubmit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.general.successLight};
  width: 30px;
  border-radius: 0 ${({ theme }) => theme.general.borderRadius.medium}
    ${({ theme }) => theme.general.borderRadius.medium} 0;
  svg {
    color: ${({ theme }) => theme.colors.general.success};
  }
`;

const Input = styled(Field)`
  width: 100%;
  border: 1px solid
    ${({ iserror, theme }) => {
      return iserror ? theme.colors.general.error : theme.colors.general.light;
    }};

  padding: 0 4px;
  font-size: 1.15rem;
  height: ${({ inputheight }) => inputheight};
  margin: 0;
  outline: none;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium} 0 0
    ${({ theme }) => theme.general.borderRadius.medium};
`;

const Button = styled.button`
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  color: ${({ theme }) => theme.colors.primary.dark};
  height: ${({ inputheight }) => inputheight};
  border-left: none;
  cursor: pointer;
  border-radius: 0 ${({ theme }) => theme.general.borderRadius.medium}
    ${({ theme }) => theme.general.borderRadius.medium} 0;
  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.general.light};
    outline: none;
  }
`;

const InputErrorMessage = styled(ErrorMessage)`
  color: ${({ theme }) => theme.colors.general.error};
`;

function OneInputForm({
  inputHeight,
  inputComponent,
  inputId,
  placeholder,
  initialValue,
  validationYup,
  handleSubmit,
}) {
  return (
    <Formik
      initialValues={{ [inputId]: initialValue }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        [inputId]: validationYup,
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleSubmit(values);
      }}
    >
      {({ isSubmitting, errors, values, touched }) => {
        return (
          <>
            <CustomForm>
              <Input
                inputheight={inputHeight}
                component={inputComponent}
                iserror={errors[inputId] && touched[inputId] ? 1 : 0}
                type="text"
                id={inputId}
                name={inputId}
                placeholder={placeholder}
              />
              {initialValue == values[inputId] ? (
                <SuccessSubmit>
                  <BsCheckLg />
                </SuccessSubmit>
              ) : (
                <Button
                  type="submit"
                  disabled={
                    isSubmitting || (errors[inputId] && touched[inputId])
                  }
                  inputheight={'calc(' + inputHeight + ' + 2px)'}
                >
                  Save
                </Button>
              )}
            </CustomForm>
            <InputErrorMessage name={inputId} component="small" />
          </>
        );
      }}
    </Formik>
  );
}
OneInputForm.propTypes = {
  inputHeight: PropTypes.string,
  inputComponent: PropTypes.string,
  initialValue: PropTypes.string,
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  validationYup: PropTypes.any,
  handleSubmit: PropTypes.func,
};
export default OneInputForm;
