import React from 'react';
import PropTypes from 'prop-types';

const WithLayout = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};
WithLayout.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.func,
};
export default WithLayout;
