import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.h2`
  padding: 0;
  margin: 0;
`;
const Title = ({ title }) => {
  return <Header>{title}</Header>;
};
Title.propTypes = {
  title: PropTypes.string,
};
export default Title;
