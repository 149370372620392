/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { ButtonForm } from 'components/styles';
import * as Yup from 'yup';

const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Input = styled(Field)`
  font-size: 1.15rem;
  margin: 0;
  padding: 8px;
  outline: none;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  border: 1px solid
    ${({ iserror, theme }) => {
      return iserror ? theme.colors.general.error : theme.colors.general.light;
    }};
`;

const InputErrorMessage = styled(ErrorMessage)`
  color: ${({ theme }) => theme.colors.general.error};
`;

function OneInputForm({ config }) {
  const initialize = (config) => {
    let initialValues = {};
    let validationOptions = {};
    config.inputs.forEach((input) => {
      initialValues[input.id] = input.initialValue;
      validationOptions[input.id] = input.yupValidation;
    });
    return [initialValues, validationOptions];
  };
  const [initialValues, validationOptions] = initialize(config);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape(validationOptions)}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        config.handleSubmit(values);
      }}
    >
      {({ isSubmitting, errors, values, touched }) => {
        return (
          <>
            <CustomForm>
              {config.inputs.map(
                ({
                  id,
                  type,
                  component,
                  placeholder,
                  autocomplete,
                  isHidden,
                }) => {
                  return (
                    <React.Fragment key={id}>
                      <Input
                        style={{ display: isHidden ? 'none' : 'block' }}
                        component={component}
                        iserror={errors[id] && touched[id] ? 1 : 0}
                        type={type}
                        id={id}
                        name={id}
                        placeholder={placeholder}
                        autoComplete={autocomplete}
                      />
                      <InputErrorMessage name={id} component="small" />
                    </React.Fragment>
                  );
                },
              )}

              <ButtonForm type="submit" disabled={isSubmitting}>
                {config.submitButtonName}
              </ButtonForm>
            </CustomForm>
          </>
        );
      }}
    </Formik>
  );
}
OneInputForm.propTypes = {
  config: PropTypes.object,
};
export default OneInputForm;
