/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AddImagesDropzoneWrapped, ImageWrapped } from './components';
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
`;
const SetImages = ({ project }) => {
  return (
    <Container>
      <AddImagesDropzoneWrapped projectId={project.id} />
      {project?.images?.length ? (
        project.images.map(({ id, compressed_url, original_url, notes }, i) => {
          return (
            <ImageWrapped
              key={i}
              imageId={id}
              projectId={project.id}
              image={compressed_url ? compressed_url : original_url}
              alt={notes}
            />
          );
        })
      ) : (
        <span></span>
      )}
      <InvisibleImagePlaceholders />
    </Container>
  );
};
const InvisibleImagePlaceholders = () => {
  return (
    <>
      {[1, 2, 3].map((el) => {
        return <div key={el} style={{ width: '180px' }}></div>;
      })}
    </>
  );
};

SetImages.propTypes = {
  project: PropTypes.object,
};

export default SetImages;
