/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';
import { AuthForms } from 'views/authPages/Authentication';
import { HeroDescription } from '../components';

const Wrapper = styled.section`
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  border-radius: 0 0 ${({ theme }) => theme.general.borderRadius.xLarge}
    ${({ theme }) => theme.general.borderRadius.xLarge};

  background: ${({ theme }) => theme.general.gradients.primary};
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  min-height: 55vh;
`;
const Inner = styled.div`
  background-image: url(${require('assets/img/general/home-hero.png')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 55vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

function Home() {
  return (
    <Wrapper>
      <Inner>
        <HeroDescription />
        <AuthForms />
      </Inner>
    </Wrapper>
  );
}

export default Home;
