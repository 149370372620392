/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';

import { NavLink } from 'react-router-dom';

import { ButtonSidebar } from 'components/styles';
import styled from 'styled-components';
import { BsFillPersonFill } from 'react-icons/bs';
import { MdDashboard, MdAddBox } from 'react-icons/md';
import { HiShare } from 'react-icons/hi';

const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

const UnorderedList = styled.ul`
  list-style-type: none;
  position: sticky;
  top: 48px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: calc(20vh);
  padding: 0;
  background: white;
  border-radius: 8px;
  .sidebar-active {
    svg {
      color: ${({ theme }) => theme.colors.primary.medium};
    }
  }
  a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a:hover svg,
  a:active svg,
  a:focus svg {
    color: ${({ theme }) => theme.colors.primary.medium};
  }
`;

const Content = () => {
  const links = [
    {
      id: 'dashboard-tt',
      name: 'Dashboard',
      link: '/dashboard',
      img: <MdDashboard size="30px" />,
      tooltipMes: 'Dashboard',
    },
    {
      id: 'account-tt',
      name: 'Account',
      link: '/profile',
      img: <BsFillPersonFill size="30px" />,
      tooltipMes: 'My Account',
    },

    {
      id: 'projects-tt',
      name: 'Projects',
      link: '/projects',
      img: <MdAddBox size="30px" />,
      tooltipMes: 'Projects',
    },
    {
      id: 'prospects-tt',
      name: 'Prospects',
      link: '/prospects',
      img: <HiShare size="30px" />,
      tooltipMes: 'Prospects',
    },
  ];
  return (
    <UnorderedList>
      {links.map(({ link, img, id, name }) => {
        return (
          <ListItem key={id}>
            <NavLink
              to={link}
              aria-label={name}
              className={({ isActive }) => (isActive ? 'sidebar-active' : '')}
            >
              {img}
            </NavLink>
          </ListItem>
        );
      })}
    </UnorderedList>
  );
};

export default Content;
