import React from 'react';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import * as Yup from 'yup';
import { useActivityPanel } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_ABOUT } from 'store/actions';
import { userApi } from 'api';

function SetAbout() {
  const dispatch = useDispatch();
  const { addActivity, setLoading } = useActivityPanel();
  const { token, about } = useSelector((state) => state).user;
  function handleSubmit(values) {
    setLoading();
    userApi
      .updateAbout(token, values.about)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'Description was set to ' + values.about,
        });
        dispatch({
          type: UPDATE_USER_ABOUT,
          about: values.about,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="100px"
      inputComponent="textarea"
      initialValue={about}
      inputId="about"
      placeholder="About"
      validationYup={Yup.string().trim()}
      handleSubmit={handleSubmit}
    />
  );
}

export default SetAbout;
