/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  h1,
  h2 {
    color: white;
  }
  background: rgba(0, 0, 0, 0.4);
  padding: 12px;
  border-radius: 8px;
`;

function Description() {
  return (
    <Wrapper>
      <h1>Experience Warehouse</h1>
      <h2>On your mark, get set, share!</h2>
    </Wrapper>
  );
}

export default Description;
