import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components';
import { BsArrowsMove, BsPencilSquare, BsTrash } from 'react-icons/bs';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  button {
    background: transparent;
    padding: 0;
    border: none;

    svg:hover {
      fill: ${({ theme }) => theme.colors.general.danger};
    }
  }
`;
const ImageWrappedBar = ({ handleImageEdit, handleImageDelete }) => {
  return (
    <Container>
      <IconButton>
        <BsArrowsMove size="18px" style={{ marginBottom: '-1px' }} />
      </IconButton>
      <IconButton handleClick={handleImageEdit}>
        <BsPencilSquare size="18px" style={{ marginBottom: '-3px' }} />
      </IconButton>
      <IconButton handleClick={handleImageDelete}>
        <BsTrash size="18px" style={{ marginBottom: '-3px' }} />
      </IconButton>
    </Container>
  );
};

ImageWrappedBar.propTypes = {
  handleImageEdit: PropTypes.func,
  handleImageDelete: PropTypes.func,
};

export default ImageWrappedBar;
