import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

function Privacy() {
  const userInfo = useRef(null);
  const useUserInfo = useRef(null);
  const shareUserInfo = useRef(null);
  const secureUserInfo = useRef(null);
  const accessUserInfo = useRef(null);
  const transferUserInfo = useRef(null);
  const otherUserInfo = useRef(null);
  return (
    <section>
      <br />
      <h1>Privacy</h1> <br />
      <div>
        <p>
          This privacy policy (“Privacy Policy”) governs and describes how
          ExperienceWarehouse and its subsidiaries (“EW”, “we,” “us” or “our”)
          may collect, use, and disclose personal information when you use our
          products or services, or otherwise interact with ExperienceWarehouse,
          unless a different privacy policy is displayed. This policy also
          explains your choices about how we use information about you. Your
          choices include how you can object to certain uses of information
          about you and how you can access and update certain information about
          you. If you do not agree with this policy, do not access or use our
          Services or interact with any other aspect of our business.
        </p>
        <p>
          ExperienceWarehouse offers portfolio creation and sharing tools, which
          help users to document and share their professional experience. We
          refer to this product together with our other services and websites as
          &quot;Services&quot; in this policy.
        </p>
        <p>
          We have updated this Privacy Policy in order to comply with changes in
          data protection law. In addition, we have worked to make the Privacy
          Policy clearer and more understandable.
        </p>
      </div>
      <div>
        <br />
        <h2>What we explain in this policy</h2>
        <ul>
          <li>
            <Link to="#" onClick={() => userInfo.current.scrollIntoView()}>
              What information we collect about you
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => useUserInfo.current.scrollIntoView()}>
              How we use the information
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => shareUserInfo.current.scrollIntoView()}>
              How we share the information
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={() => secureUserInfo.current.scrollIntoView()}
            >
              How we store and secure the information
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={() => accessUserInfo.current.scrollIntoView()}
            >
              How to access and control your information
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={() => transferUserInfo.current.scrollIntoView()}
            >
              How we transfer the information we collect internationally
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => otherUserInfo.current.scrollIntoView()}>
              Other important privacy-related information
            </Link>
          </li>
        </ul>
      </div>
      <div ref={userInfo}>
        <br />
        <br />
        <h2>What information we collect about you</h2>
        <br />
        <h3>Information you provide to us</h3>
        <ul>
          <li>
            <strong>Account and profile information:</strong> We collect
            information about you when you register for an account, create or
            modify your profile, set preferences, sign-up for or make purchases
            through the Services. For example, this includes your first name,
            last name, email address, profile picture, and notification
            settings.
          </li>
          <li>
            <strong>Content you provide through the Services:</strong> Using
            ExperienceWarehouse may involve creating, editing, and sharing
            content. For example, this includes uploading illustrations related
            to a project or sharing portfolio to a potential employee.
          </li>
          <li>
            <strong>
              Information you provide through our support channels:
            </strong>{' '}
            The Services also include customer support, where you may choose to
            submit information regarding a problem you are experiencing with a
            Service. Whether you contact customer support via email, speak to
            one of our representatives directly or otherwise engage with our
            support team, you will be asked to provide contact information, a
            summary of the problem you are experiencing, and any other
            documentation, screenshots or information that would be helpful in
            resolving the issue.
          </li>
          <li>
            <strong>Payment information:</strong> We collect certain payment and
            billing information when you register for certain paid Services. For
            example, we ask you to provide payment information, such as payment
            card details, which we collect via secure payment processing
            services.
          </li>
        </ul>
        <h3>Information we collect automatically</h3>
        <p>
          We collect information about you when you use our Services, including
          browsing our websites and taking certain actions within the Services.
          This includes:
        </p>
        <ul>
          <li>Internet Protocol (IP) addresses.</li>
          <li>Internet Service Provider (ISP).</li>
          <li>Browser type.</li>
          <li>Operating system.</li>
          <li>Date/time stamp.</li>
          <li>Browsing actions and browsing patterns.</li>
        </ul>
        <p>
          <strong>Cookies and other tracking technologies</strong>
        </p>
        <p>
          Cookies are small text files that are sent by us to your computer or
          mobile device when you use our Services. They allow certain features
          and functions to work and are unique to your account or browser.
          Session-based cookies only last while your browser is open and are
          automatically deleted when you close it. Persistent cookies remain
          until you or your browser delete them or until they expire. We use
          cookies to collect and store information such as:
        </p>
        <ul>
          <li>
            Authentication information: If you&apos;re signed in to our
            Services, cookies help us show you the right information and
            personalize your experience.
          </li>
          <li>Document referrer information.</li>
          <li>Information about which variant(s) of the Services you use.</li>
        </ul>
        <p>
          <strong>Google Analytics</strong>
        </p>
        <p>
          This website uses Google Analytics, a web analytics service provided
          by Google, Inc. (“Google”). Google Analytics uses Cookies to store
          information. On behalf of the website provider Google will use this
          information for the purpose of evaluating your use of the website,
          compiling reports on website activity for website operators and
          providing other services relating to website activity and internet
          usage to the website provider. Google will not associate your IP
          address with any other data held by Google. You may refuse the use of
          cookies by selecting the appropriate settings on your browser.
          However, please note that if you do this, you may not be able to use
          the full functionality of this website. Furthermore, you can prevent
          Google’s collection and use of data (cookies and IP address) by
          downloading and installing the browser plug-in available under this{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en-US"
            target="_blank"
            rel="noreferrer"
          >
            google tools link
          </a>
          .
        </p>
        <p>
          Further information concerning the terms and conditions of use and
          data privacy can be found at{' '}
          <a
            href="https://marketingplatform.google.com/about/analytics/terms/us/"
            target="_blank"
            rel="noreferrer"
          >
            www.google.com/analytics/terms/us.html
          </a>{' '}
          and{' '}
          <a
            href="https://policies.google.com/?hl=us"
            target="_blank"
            rel="noreferrer"
          >
            policies.google.com
          </a>
          .
        </p>
      </div>
      <div ref={useUserInfo}>
        <br />
        <br />
        <h2>How we use the information</h2>
        <p>
          How we use the information we collect depends in part on which
          Services you use, how you use them, and any preferences you have
          communicated to us. Below are the specific purposes for which we use
          the information we collect about you:
        </p>
        <ul>
          <li>To provide the Services.</li>
          <li>To personalize your experience.</li>
          <li>To aid in research and development.</li>
          <li>To communicate with you about the Services.</li>
          <li>To market, promote, and drive engagement with the Services.</li>
          <li>To provide customer support</li>
          <li>To prevent fraud and abuse.</li>
          <li>For any other purpose with your consent.</li>
        </ul>
      </div>
      <div ref={shareUserInfo}>
        <br /> <br />
        <h2>How we share the information</h2>
        <br />
        <h3>Sharing with third parties</h3>
        <ul>
          <li>
            <strong>Service providers:</strong>We use third-party service
            providers to help us with website and application development,
            hosting, maintenance, backup, storage, infrastructure, payment
            processing, analysis and other services. These service providers may
            need to access or use information about you in order to provide
            their services. We instruct these service providers to protect your
            information and follow our policies and procedures. You can find
            more information about these service providers on our{' '}
            <Link to="/subprocesses">
              ExperienceWarehouse Subprocesses page
            </Link>
            .
          </li>
          <li>
            <strong>With your consent:</strong> We share information about you
            with third parties when you give us consent to do so.
          </li>
          <li>
            <strong>Compelled disclosure:</strong> We reserve the right to use
            or disclose your Personal Information if required by law or if we
            reasonably believe that use or disclosure is necessary to protect
            our rights, protect your safety or the safety of others, investigate
            fraud, or comply with a law, court order, or legal process.
          </li>
        </ul>
      </div>
      <div ref={secureUserInfo}>
        <br /> <br />
        <h2>How we store and secure the information</h2>
        <br />
        <h3>Information storage and security</h3>
        <p>
          We use data hosting service providers to host the information we
          collect, and we use technical measures to secure your data. You can
          learn more about services we use on our{' '}
          <Link to="/subprocesses">ExperienceWarehouse Subprocesses page</Link>.
          While we implement safeguards designed to protect your information, no
          security system is impenetrable and due to the inherent nature of the
          Internet, we cannot guarantee that data, during transmission through
          the Internet or while stored on our systems or otherwise in our care,
          is absolutely safe from intrusion by others. You can learn more about
          selected security measures on{' '}
          <Link to="/security">security page</Link>. We will respond to requests
          about information storage and security within a reasonable timeframe.
        </p>
        <h3>How long we keep information</h3>
        <p>
          We may retain your personal information for as long as necessary for
          the original purpose of collection. This may include retaining your
          personal information while you have an account to use the Services and
          for a reasonable time after that. We may also retain your personal
          information for the period of time needed to pursue our legitimate
          business interests, conduct audits, comply with our legal obligations,
          resolve disputes, and enforce our agreements.
        </p>
      </div>
      <div ref={accessUserInfo}>
        <br /> <br />
        <h2>How to access and control your information</h2>
        <br />
        <h3>Right to access, update, and delete information</h3>
        <p>
          You have the ability to access and update certain information about
          you through the Services. You can do this by accessing your account
          and using the provided tools to update your profile information.
        </p>
        <p>
          You may also request to access or update your information by
          contacting us using the information provided at the end of this
          Privacy Policy. We will respond to your request in a reasonable
          timeframe and inform you of the action we have taken.
        </p>
        <br />
        <h3>Right to restrict information processing</h3>
        <p>
          In certain cases, you may request that we stop accessing, storing,
          using, and otherwise processing your information if you believe we do
          not have the appropriate rights to do so.
        </p>
        <p>
          To make this request, please contact us using the information provided
          at the end of this Privacy Policy. We will respond to your request in
          a reasonable timeframe and inform you of the action we have taken.
        </p>
        <br />
        <h3>Right to data portability</h3>
        <p>
          Data portability allows you to obtain some of your information in a
          format that can be transferred from one service provider to another.
          You have the ability to export certain information from the Services
          and related documentation. If you request it by contacting us using
          the information provided at the end of this Privacy Policy, we will
          provide you with an electronic file of your basic account information
          and information that is under your sole control. We will respond to
          your request in a reasonable timeframe and inform you of the action we
          have taken.
        </p>
      </div>
      <div ref={transferUserInfo}>
        <br /> <br />
        <h2>How we transfer the information we collect internationally</h2>
        <p>
          We collect information from locations around the world and may
          transfer, process, and store your information outside of your country
          of residence for the purpose of providing you with the Services. This
          may involve transferring your information to countries where we,
          ExperienceWarehouse, or our third-party service providers operate. We
          take steps to protect your information whenever it is transferred.
        </p>
      </div>
      <div ref={otherUserInfo}>
        <br /> <br />
        <h2>Other important privacy-related information</h2> <br />
        <h3>Age limitations</h3>
        <p>
          In accordance with applicable law, ExperienceWarehouse does not allow
          the use of the Services by anyone younger than 16 years old. If you
          become aware that anyone younger than 16 has unlawfully provided us
          with personal data, please contact us and we will take steps to delete
          that information.
        </p>
        <br />
        <h3>Changes to our Privacy Policy</h3>
        <p>
          We may update this privacy policy from time to time and will post any
          changes on this page. If the changes are significant, we will provide
          a more prominent notice, such as by adding a notice on the Services
          homepages, login screens, or by sending you an email notification. We
          encourage you to review our privacy policy whenever you access the
          Services to stay informed about our information practices and the ways
          you can protect your privacy.
        </p>
        <p>
          If you disagree with any changes to this privacy policy, you will need
          to stop using the Services and deactivate or delete your account(s).
        </p>
      </div>
      <br />
      <div>
        <h3>Contact Us</h3>
        <p>
          Your information is controlled by ExperienceWarehouse. If you have
          questions or concerns about how your information is handled, please
          direct your inquiry to ExperienceWarehouse, as set forth below or:
        </p>
        <p>
          <strong>Experience Warehouse</strong>
        </p>
        <p>Brooklyn, New York, United States</p>
        <p>
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>
        </p>
      </div>
      <br />
    </section>
  );
}

export default Privacy;
