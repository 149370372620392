import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'components/styles';
import { TopBar, Footer } from '.';

const DashboardFW = ({ children }) => {
  return (
    <>
      <TopBar />

      <Box>{children}</Box>
      <Footer />
    </>
  );
};

DashboardFW.propTypes = {
  children: PropTypes.object,
};

export default DashboardFW;
