import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { CgLogOff } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOG_OFF, SET_LAYOUT_STATE } from 'store/actions';
import { removeToken } from 'utils/authFunctions';
import { Card, ButtonSidebar } from 'components/styles';
import { CLOSED_ALL, MENU, SIDEBAR } from 'utils/topBarStates';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsFillCaretUpFill } from 'react-icons/bs';
import Logo from 'components/Logo';

const links = [
  { name: 'Home', link: '/' },
  { name: 'About', link: '/about' },
  { name: 'Feedback', link: '/feedback' },
];

const UnorderedList = styled.ul`
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    gap: 4px;
  }
`;

const LargeScreenList = styled.li`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    display: none;
  }
`;

const HamburgerListItem = styled.li`
  display: none;
  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    display: flex;
  }
`;

const MobileDropdown = styled.div`
  display: none;
  position: absolute;
  justify-content: right;
  top: 44px;
  right: 0;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    display: flex;
  }
`;

const MobileUL = styled.ul`
  list-style: none;
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding: 0;
  margin: 8px;
  a {
    display: block;
    padding-left: 16px;
  }
`;

const LogoButton = styled.button`
  background: white;
  border: 0;
  color: inherit;
  padding: 0;
  margin: 0;
`;

const Content = () => {
  const { user, layout } = useSelector((state) => state);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleLogOff = () => {
    removeToken();
    dispatch({ type: LOG_OFF });
    navigate('/');
  };
  const handleExpand = () => {
    if (layout.active === MENU) {
      dispatch({ type: SET_LAYOUT_STATE, active: CLOSED_ALL });
    } else {
      dispatch({ type: SET_LAYOUT_STATE, active: MENU });
    }
  };
  const handleLogoClick = () => {
    dispatch({ type: SET_LAYOUT_STATE, active: SIDEBAR });
    if (user.token) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <LogoButton
        onClick={handleLogoClick}
        aria-label="Experience Warehouse Logo"
      >
        <Logo />
      </LogoButton>
      <div>
        <UnorderedList>
          {links.map(({ name, link }) => {
            return (
              <LargeScreenList key={name}>
                <NavLink
                  to={link}
                  className={({ isActive }) =>
                    isActive ? 'nav-active' : 'none'
                  }
                >
                  {name}
                </NavLink>
              </LargeScreenList>
            );
          })}

          {user.token ? (
            <li>
              <ButtonSidebar onClick={handleLogOff}>
                <CgLogOff size="30px" />
              </ButtonSidebar>
            </li>
          ) : (
            ''
          )}
          <HamburgerListItem>
            <ButtonSidebar onClick={handleExpand}>
              {layout.active === MENU ? (
                <BsFillCaretUpFill size="30px" />
              ) : (
                <AiOutlineMenu size="30px" />
              )}
            </ButtonSidebar>
          </HamburgerListItem>
        </UnorderedList>
        {layout.active === MENU ? (
          <MobileDropdown>
            <Card w="100%" r="0 0 8px 8px">
              <MobileUL>
                {links.map(({ name, link }) => {
                  return (
                    <li key={name}>
                      <NavLink
                        to={link}
                        className={({ isActive }) =>
                          isActive ? 'nav-active' : 'none'
                        }
                      >
                        {name}
                      </NavLink>
                    </li>
                  );
                })}
              </MobileUL>
            </Card>
          </MobileDropdown>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

Content.propTypes = {
  links: PropTypes.array,
  logo: PropTypes.func,
};

export default Content;
