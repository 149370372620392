/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Widget } from 'components';
import { GoPrimitiveDot } from 'react-icons/go';
import { useTheme } from 'styled-components';
import { useStatistics } from 'hooks';
import { BsFillPersonFill } from 'react-icons/bs';

const Body = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-around;
  flex-wrap: wrap;
  svg {
    color: green;
    margin-bottom: -3px;
    margin-right: -2px;
  }
`;

function AccountItem({ name, color }) {
  return (
    <span>
      <GoPrimitiveDot size="1.15rem" fill={color} />
      {name}
    </span>
  );
}

function Account({ link }) {
  const theme = useTheme();
  const { profileStatistics, constants } = useStatistics();
  const { SUCCESS_MIN } = constants;

  return (
    <Widget
      link={link}
      title="Profile"
      score={profileStatistics.averageScore}
      icon={<BsFillPersonFill size="30px" />}
    >
      <Body>
        {profileStatistics.items.map(({ name, score }, i) => {
          return (
            <AccountItem
              key={i}
              name={name}
              color={
                score >= SUCCESS_MIN
                  ? theme.colors.general.successLight
                  : theme.colors.general.warningLight
              }
            />
          );
        })}
      </Body>
    </Widget>
  );
}
AccountItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
};

Account.propTypes = {
  link: PropTypes.string,
};
export default Account;
