import React from 'react';
import PropTypes from 'prop-types';
import Content from './Content';
import styled from 'styled-components';
const SideBarContainer = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  z-index: 1;
`;

const SideBar = () => {
  return (
    <SideBarContainer>
      <Content />
    </SideBarContainer>
  );
};

SideBar.propTypes = {
  test: PropTypes.string,
};

export default SideBar;
