import React from 'react';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import * as Yup from 'yup';
import { useActivityPanel } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_JOB } from 'store/actions';
import { userApi } from 'api';
function SetJob() {
  const dispatch = useDispatch();
  const { addActivity, setLoading } = useActivityPanel();
  const { token, job } = useSelector((state) => state).user;
  function handleSubmit(values) {
    setLoading();
    userApi
      .updateJob(token, values.job)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'Specialization was set to ' + values.job,
        });
        dispatch({
          type: UPDATE_USER_JOB,
          job: values.job,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="28px"
      inputComponent="input"
      initialValue={job}
      inputId="job"
      placeholder="Job"
      validationYup={Yup.string().trim()}
      handleSubmit={handleSubmit}
    />
  );
}

export default SetJob;
