import decode from 'jwt-decode';
const tokenIdentifier = 'token';
export function getToken() {
  return localStorage.getItem(tokenIdentifier);
}

export function setToken(token) {
  localStorage.setItem(tokenIdentifier, token);
}

export function removeToken() {
  localStorage.removeItem(tokenIdentifier);
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}
export function getProfile() {
  return decode(localStorage.getItem(tokenIdentifier));
}
export function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = localStorage.getItem(tokenIdentifier);
  return !!token && isTokenExpired(token);
}
