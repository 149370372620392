import React from 'react';
import { UserMainCard } from 'components/UserCards';
import styled from 'styled-components';
import { SetName, SetJob, SetAbout, SetImages } from './forms';
import { useSelector } from 'react-redux';
import { Box } from 'components/styles';

const FormsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 32px 0;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.screenSizes.xs}) {
    flex-wrap: wrap;
    justify-content: left;
  } ;
`;
const Account = () => {
  const { user } = useSelector((state) => state);
  return (
    <>
      <h2 style={{ margin: '32px 0' }}>Profile</h2>
      <Box
        direction="column"
        gap="8px"
        w="100%"
        justify="center"
        align="center"
      >
        <Box justify="center" maxW="750px">
          <UserMainCard
            full_name={user.full_name}
            job={user.job}
            about={user.about}
            profile_image={user.profile_image}
          />

          <FormsContainer>
            <div>
              <h3>1. Profile Image</h3>
              <SetImages />
            </div>
            <Box direction="column" gap="8px" w="100%">
              <div>
                <h3>2. Full Name</h3>
                <SetName />
              </div>
              <div>
                <h3>3. Job/Specialization</h3>
                <SetJob />
              </div>
              <div>
                <h3>4. Bio</h3>
                <SetAbout />
              </div>
            </Box>
          </FormsContainer>
        </Box>
      </Box>
    </>
  );
};

export default Account;
