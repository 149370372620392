/* eslint-disable indent */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TopMessage } from 'components';
import { MainCard } from 'components/ProjectCards';
import { portfolioApi } from 'api';
import { Link, useParams } from 'react-router-dom';
import { UserMainCard } from 'components/UserCards';
import { Box, CardsContainer } from 'components/styles';
import { Container } from 'components/styles';
import { Loading, NotFound } from 'views/landingPages';
import ProspectSearchBar from './ProspectSearchBar';
import styled from 'styled-components';

const Top = styled.div`
  display: flex;
  align-items: stretch;
  gap: 16px;
  @media (max-width: ${({ theme }) => theme.screenSizes.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const getOrderSkills = (project, prospectSkills) => {
  return project.skills
    .map((skill) => {
      return { ...skill, active: prospectSkills[skill.id] ? true : false };
    })
    .sort((a, b) => {
      if (a.active && b.active) {
        return 0;
      }
      if (!a.active) {
        return 1;
      }
      if (!b.active) {
        return -1;
      }
    });
};

const getProjectOrder = (project) => {
  return project.skills.reduce((accumulator, project) => {
    if (project.active) {
      accumulator += 1;
    }
    return accumulator;
  }, 0);
};

const containsSearchedString = (project, searchedWord) => {
  const values =
    `${project?.name} ${project?.responsibilities}`.toLocaleLowerCase();
  searchedWord = searchedWord.toLocaleLowerCase();
  return (
    values.search(searchedWord) !== -1 ||
    project.skills.some((value) => {
      return value.skill.toLocaleLowerCase().indexOf(searchedWord) !== -1;
    })
  );
};

const objectifySkills = (rawProspectSkills) => {
  return rawProspectSkills.reduce((accumulator, skill) => {
    if (!accumulator[skill.id]) {
      accumulator[skill.id] = skill.skill;
    }
    return accumulator;
  }, {});
};

const [LOADING, SUCCESS, ERROR] = ['LOADING', 'SUCCESS', 'ERROR'];

function Portfolio() {
  const { id } = useParams();
  const { token } = useSelector((state) => state).user;

  // original data, not to be changed
  const [prospect, setProspect] = useState({});
  const [searchedWord, setSearchedWord] = useState('');
  const [prospectSkills, setProspectSkills] = useState({});
  const [apiState, setApiState] = useState(LOADING);

  useEffect(() => {
    portfolioApi
      .getPortfolioItems(token, id)
      .then((res) => {
        setProspect(res.data);
        setProspectSkills(objectifySkills(res.data.skills));
        setApiState(SUCCESS);
      })
      .catch(() => {
        setApiState(ERROR);
      });
  }, []);

  const handleProspectSkillClick = (key, value) => {
    setProspectSkills((prev) => {
      return { ...prev, [key]: prev[key] ? null : value };
    });
  };

  const handleSearch = (word) => {
    setSearchedWord(word);
  };

  const resetActiveSkills = () => {
    setProspectSkills(objectifySkills(prospect?.skills));
  };
  const clearActiveSkills = () => {
    setProspectSkills({});
  };

  const distinctUserSkills = useMemo(() => {
    const distinctObjects = prospect?.users?.length
      ? prospect?.users[0].projects.reduce((accumulator, project) => {
          project.skills.forEach((object) => {
            if (!accumulator[object.id]) {
              accumulator[object.id] = object.skill;
            }
          });
          return accumulator;
        }, {})
      : {};
    return distinctObjects;
  }, [prospect]);

  const projects = useMemo(() => {
    if (!prospect?.users?.length) return;
    return (
      prospect.users[0].projects
        // order skills in each project based on active prospectSkills
        .map((project) => {
          return {
            ...project,
            skills: getOrderSkills(project, prospectSkills),
          };
        })
        // generate score for each project based on amount of active skills
        .map((project) => {
          return {
            ...project,
            score: getProjectOrder(project),
          };
        })
        // sort projects based on project score generated above
        .sort((a, b) => {
          if (a.score > b.score) {
            return -1;
          }
          if (a.score < b.score) {
            return 1;
          }
          return 0;
        })
        .filter((project) => {
          return containsSearchedString(project, searchedWord);
        })
    );
  }, [prospect, searchedWord, prospectSkills]);

  if (apiState === LOADING) return <Loading />;
  if (apiState === ERROR) return <NotFound />;

  return (
    <>
      {token ? (
        <TopMessage type="info">
          <Box justify="center">
            Preview Mode &nbsp; <Link to="/prospects">Back to Prospects?</Link>
          </Box>
        </TopMessage>
      ) : (
        ''
      )}
      <Container>
        <Box
          as={'main'}
          gap="32px"
          m="32px 0 0"
          minH="100vh"
          direction="column"
        >
          <Top>
            <UserMainCard
              full_name={prospect?.users[0]?.full_name}
              job={prospect?.users[0]?.job}
              about={prospect?.users[0]?.about}
              profile_image={prospect?.users[0]?.profile_image}
            />
            <ProspectSearchBar
              handleSearch={handleSearch}
              distinctUserSkills={distinctUserSkills}
              prospectSkills={prospectSkills}
              handleProspectSkillClick={handleProspectSkillClick}
              resetActiveSkills={resetActiveSkills}
              clearActiveSkills={clearActiveSkills}
              totalProjectsNumber={prospect?.users[0]?.projects.length}
              matchingProjectsNumber={projects.length}
            />
          </Top>
          <CardsContainer>
            {projects?.map(
              (
                { name, description, responsibilities, url, images, skills },
                i,
              ) => {
                return (
                  <MainCard
                    key={i}
                    title={name}
                    responsibilities={responsibilities}
                    url={url}
                    description={description}
                    images={images}
                    skills={skills}
                  />
                );
              },
            )}
            {[1, 2, 3].map((n) => (
              <Box maxW="370px" h="0" key={n + 'i'}></Box>
            ))}
          </CardsContainer>
        </Box>
      </Container>
    </>
  );
}

Portfolio.propTypes = {
  test: PropTypes.string,
};

export default Portfolio;
