import React from 'react';
import PropTypes from 'prop-types';
import { SEND_CODE_STEP } from '../authConstants';
import { userApi } from 'api';
import { MultiInputForm } from 'components/forms';
import * as Yup from 'yup';
import { Paragraph } from 'components/styles';

const SearchAccountForm = ({ setApiMessage, setAuthData }) => {
  const handleSubmit = (values) => {
    userApi
      .forgotPassword(values.email)
      .then((res) => {
        setApiMessage({
          type: 'success',
          title: 'Nice',
          message:
            'We found your account. We need to confirm now that you are the owner.',
        });
        setAuthData((prev) => {
          let newData = {
            ...prev,
            email: res.data.email,
            step: SEND_CODE_STEP,
          };
          sessionStorage.setItem('authData', JSON.stringify(newData));
          return newData;
        });
      })
      .catch((err) => {
        setApiMessage(err?.response?.data);
      });
  };

  const config = {
    inputs: [
      {
        id: 'email',
        initialValue: '',
        label: 'Email',
        type: 'text',
        component: 'input',
        placeholder: 'Email',
        autocomplete: 'Email',
        yupValidation: Yup.string()
          .trim()
          .email('Email is invalid')
          .required('No email provided'),
      },
    ],
    handleSubmit,
    submitButtonName: 'Search',
  };

  return (
    <>
      <Paragraph>
        To reset password, let&apos;s start by searching your account!
      </Paragraph>
      <MultiInputForm config={config} />
    </>
  );
};

SearchAccountForm.propTypes = {
  setApiMessage: PropTypes.func,
  setAuthData: PropTypes.func,
};

export default SearchAccountForm;
