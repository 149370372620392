import React from 'react';
import PropTypes from 'prop-types';
import { userApi } from 'api';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  SIGNUP,
  VERIFY_CODE_STEP,
} from 'views/authPages/Authentication/authConstants';
import { MultiInputForm } from 'components/forms';

const SignUp = ({ setApiMessage }) => {
  const navigate = useNavigate();

  const handleSubmit = ({ full_name, email, password }) => {
    userApi
      .signUp(email, password, full_name)
      .then((res) => {
        let authData = {
          email: res.data.email,
          step: VERIFY_CODE_STEP,
          type: SIGNUP,
        };
        sessionStorage.setItem('authData', JSON.stringify(authData));
        navigate('/auth');
      })
      .catch((err) => {
        return setApiMessage(err.response.data);
      });
  };

  const config = {
    inputs: [
      {
        id: 'full_name',
        initialValue: '',
        label: 'Full Name',
        type: 'text',
        component: 'input',
        placeholder: 'Full Name',
        autocomplete: 'name',
        yupValidation: Yup.string()
          .matches(/[a-zA-Z]/, 'Full Name can only contain Latin letters.')
          .min(3, 'Full name is too short')
          .max(100, 'Full name is too long')
          .required('Full name is required'),
      },
      {
        id: 'email',
        initialValue: '',
        label: 'Email',
        type: 'text',
        component: 'input',
        placeholder: 'Email',
        autocomplete: 'email',
        yupValidation: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
      },
      {
        id: 'password',
        initialValue: '',
        label: 'Password',
        type: 'password',
        component: 'input',
        placeholder: 'Password',
        autocomplete: 'current-password',
        yupValidation: Yup.string()
          .min(8, 'Password must be at least 8 characters long')
          .max(64, 'Password must be no more than 64 characters long')
          .matches(
            /[a-z]/,
            'Password must contain at least one lowercase letter',
          )
          .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase letter',
          )
          .matches(/\d/, 'Password must contain at least one number')
          .required('Password is required'),
      },
    ],
    handleSubmit,
    submitButtonName: 'Sign Up',
  };
  return <MultiInputForm config={config} />;
};

SignUp.propTypes = {
  setApiMessage: PropTypes.func,
};

export default SignUp;
