import React from 'react';
import styled from 'styled-components';
import {
  AccountWidget,
  ProjectsWidget,
  ProspectsWidget,
} from 'components/widgets';

import { useSelector } from 'react-redux';
import { Widget } from 'components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.screenSizes.md}) {
    flex-wrap: wrap;
  }

  section {
    width: 100%;
  }

  aside {
    min-width: 370px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
      min-width: 280px;
    }
  }
`;

const Dashboard = () => {
  const { full_name } = useSelector((state) => state).user;
  return (
    <>
      <h2 style={{ margin: '32px 0' }}>Dashboard</h2>{' '}
      <Wrapper>
        <section>
          <h3>Hi, {full_name}!</h3>
        </section>
        <aside>
          <AccountWidget link="/profile" />
          <ProjectsWidget link="/projects" />
          <ProspectsWidget link="/prospects" />
          <Widget title="Feedback" link="/feedback" score={100}>
            <>
              helps us grow and improve,
              <br />
              It shows us where we need to move.
              <br />
              Without it, we may stray from our path, <br />
              But with it, we can learn and adapt.
            </>
          </Widget>
        </aside>
      </Wrapper>
    </>
  );
};

export default Dashboard;
