import { keyframes } from 'styled-components';
export const hideNorthWest = keyframes`
  0% {
    top: 0;
    left: 0px;
  }
  100%{
    top: -250px;
    left: -180px;
    -webkit-transform: scale(0.05);
    -moz-transform: scale(0.05);
    -o-transform: scale(0.05);
    transform: scale(0.05);
    border-radius: 50px;
    z-index:1;
  }
`;
