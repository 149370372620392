import React from 'react';
import { Box, Loader } from 'components/styles';

function Loading() {
  return (
    <Box justify="center" align="center" h="100vh" w="100%">
      <Loader size="100px" />
    </Box>
  );
}

export default Loading;
