/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable indent */

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FsLightbox from 'fslightbox-react';
import {
  BsFullscreen,
  BsChevronCompactDown,
  BsChevronCompactUp,
  BsChevronCompactRight,
  BsChevronCompactLeft,
} from 'react-icons/bs';

import { showUp, hideDown } from 'components/styles/animations';

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  bottom: 0;
  left: 0;
  right: 0;
  animation: ${({ isOpen }) => {
      return isOpen ? showUp : hideDown;
    }}
    1s ease-in-out forwards;
  .view-images-btn {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    border: none;
    padding: 4px 6px;
    svg {
      color: white;
    }
    :hover,
    :focus,
    :active {
      color: white;
      background-color: black;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 210px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: end;

  img {
    height: 100%;
    width: auto;
    object-fit: fill;
  }
`;

const DropdownButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const DropdownButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 15px;
  border-radius: ${({ theme }) => theme.general.borderRadius.small};
  border: none;
  svg {
    fill: white;
  }
  :hover,
  :focus,
  :active {
    background-color: black;
  }
`;

const Images = ({ images }) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(true);
  const [toggler, setToggler] = React.useState(false);
  const handleDropdownChange = () => setIsDropdownOpen((prev) => !prev);
  const allImages = useMemo(() => {
    return images;
  }, [images]);
  const modalImages = useMemo(() => {
    return allImages?.map(({ compressed_url, original_url }) => {
      return compressed_url ? compressed_url : original_url;
    });
  }, [images]);
  const modalTypes = useMemo(() => {
    return allImages?.map(() => 'image');
  }, [images]);

  return (
    <>
      {images?.length ? (
        <Container isOpen={isDropdownOpen}>
          <DropdownButtonContainer>
            <DropdownButton onClick={handleDropdownChange}>
              {isDropdownOpen ? (
                <BsChevronCompactDown
                  fill="rgba(256,256,256,1)"
                  size={'22px'}
                />
              ) : (
                <BsChevronCompactUp fill="rgba(256,256,256,1)" size={'22px'} />
              )}
            </DropdownButton>
          </DropdownButtonContainer>{' '}
          <ImageContainer>
            <FsLightbox
              toggler={toggler}
              sources={modalImages}
              types={modalTypes}
            />
            <img src={modalImages[0]} />
          </ImageContainer>
          <button
            className="view-images-btn"
            onClick={() => setToggler(!toggler)}
          >
            <span>
              View {images?.length} {images?.length > 1 ? 'images' : 'image'}
            </span>
            <BsFullscreen size="24px" />
          </button>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

Images.propTypes = {
  images: PropTypes.array,
};

export default Images;
