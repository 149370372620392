import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useActivityPanel } from 'hooks';
import { DELETE_PROJECT_IMAGE } from 'store/actions';
import { projectApi } from 'api';
import { CgTrashEmpty } from 'react-icons/cg';

import { EditorWrapper } from 'components';
import { Image } from '..';

const AddImageWrapper = ({ projectId, imageId, image, alt }) => {
  const { addActivity, setLoading } = useActivityPanel();
  const theme = useTheme();
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    setLoading();
    projectApi
      .deleteProjectImage(user.token, projectId, imageId)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'A project image was deleted',
        });
        dispatch({
          type: DELETE_PROJECT_IMAGE,
          projectId,
          imageId,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }, [projectId, imageId, user.token]);
  const config = useMemo(() => {
    return {
      style: {
        maxWidth: '170px',
        minWidth: 'fit-content',
        background: theme.colors.general.greyLight,
        topBar: {
          width: '30px',
          height: '30px',
        },
      },
      tools: [
        {
          name: 'Delete',
          icon: CgTrashEmpty,
          action: handleDelete,
          style: {
            icon: {
              color: theme.colors.general.grey,
              size: '22px',
              margin: '0 0 -3px',
            },
          },
        },
      ],
    };
  }, [handleDelete]);

  return (
    <EditorWrapper config={config}>
      <Image image={image} alt={alt} />
    </EditorWrapper>
  );
};

AddImageWrapper.propTypes = {
  projectId: PropTypes.number,
  imageId: PropTypes.number,
  image: PropTypes.string,
  alt: PropTypes.string,
};

export default AddImageWrapper;
