import React from 'react';
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <section>
      <br />
      <h1>Terms of Use</h1>
      <br />
      <h2>Terms of Use of ExperienceWarehouse</h2>
      <p>
        ExperienceWarehouse, located in Brooklyn, New York, United States, has
        developed a cross-platform software-as-a-service (SaaS) for creating,
        editing, and sharing portfolios. This service can be accessed at{' '}
        <a href="https://www.experiencewarehouse.com">
          www.experiencewarehouse.com
        </a>
        , its subpages, and other domains
      </p>
      <p>
        If you do not agree with these Terms of Use please do not register for
        the Services. By registering for the Services, you make an offer to
        enter into a contract of use regarding the Services pursuant to these
        Terms. The Contract of Use is concluded once we accept your offer (see
        below § 3(2)).
      </p>
      <br />
      <div>
        <h3>§ 1 Scope</h3>
        <p>
          (1) These terms apply to your use of our services, including all
          content, functions, and services activated for your account. Other
          terms of use will only be part of the contract if explicitly agreed to
          in writing. We may provide versions of these terms in languages other
          than English. If we do, the English version of these terms will govern
          our relationship and the translated version is provided for
          convenience only and will not modify the English version of these
          terms.
        </p>
        <p>(2) These terms apply to both consumers and businesses.</p>
      </div>
      <br />
      <div>
        <h3>§ 2 Object, Scope of Offer, Service Level</h3>
        <p>
          (1) Through our services, we provide the technical means for you to
          create, edit, and share content. The Services can be used as a web
          application on <Link to="/">www.experiencewarehouse.com</Link> , its
          subpages, and other domains.
        </p>
        <p>
          All data is stored in the Cloud and can be accessed from various
          devices with sufficient internet access at any time and place. More
          information on the forms and devices on which you can use our services
          can be found at <Link to="/">www.experiencewarehouse.com</Link>.
        </p>
        <p>
          (2) Upon registering for our services, you will have your own account
          that you can customize by uploading a picture or update your personal
          information. Each user account has various tools and features,
          including the ability to create, enable, disable, and share projects
          as part of a job application.
        </p>
        <p>
          (3) Our services may include both free and paid subscription plans.
          Free plans are provided at no cost, while upgrading to a paid plan
          allows you to access additional features. If you use a paid plan,
          section § 8 of these terms applies. You can find more information on
          the features and pricing of our free and paid plans at{' '}
          <Link to="/pricing">www.experiencewarehouse.com/pricing</Link>.
        </p>
        <p>
          (4) If external development partners offer applications or features
          that can be integrated into your account through our services, the
          price for these will be determined by the development partner. You
          will enter into an agreement for the use of these applications or
          features solely with the development partner.
        </p>
        <p>
          (5) Our services are available 24 hours a day, 365 days a year, with
          an average availability of at least 97%. We are not responsible for
          downtimes in which the services cannot be accessed due to technical or
          other issues beyond our control, such as force majeure or the actions
          of third parties. These downtimes are not included in our service
          level. You are entitled to use our services within the current state
          of technology. As a result, we may need to restrict access to our
          services from time to time for capacity, system security, server
          integrity, or technical reasons to maintain proper functioning. If
          possible, we will take your legitimate interests into account and
          inform you via email about any planned maintenance measures that may
          affect service availability.
        </p>
      </div>
      <br />
      <div>
        <h3>§ 3 Registration, Conclusion of Contract</h3>
        <p>
          (1) To register for our services, you must provide the required
          information truthfully and completely, unless indicated as voluntary.
          This includes an email address that we can use to contact you. By
          clicking the &quot;Sign Up&quot; button during the registration
          process, you confirm that you have read these terms and agree to the
          use of your data as described in our privacy policy at{' '}
          <Link to="/privacy">www.experiencewarehouse.com/privacy</Link>.
        </p>
        <p>
          (2) The registration process and the conclusion of the contract are
          complete when we create your account upon receiving your request for
          registration. We are not obligated to enter into a contract with you
          and reserve the right to decline your request for registration at any
          time without explanation. If we decline your request, we will delete
          all of the data you provided.
        </p>
        <br />
        <h3>§ 4 Rules for Using the Services</h3>
        <p>
          (1) When using our services, you must comply with all applicable laws
          and legal provisions.
        </p>
        <p>
          (2) A user account may not be shared or used by more than one
          individual. You are responsible for maintaining control over your
          account, including the confidentiality of your login credentials, and
          are liable for all activities that occur under your personal user
          account.
        </p>
        <p>
          (3) You are responsible for any content (including text, graphics,
          images, music, software, audio, video, or other materials) that you
          create, store, share, or make available through our services. You may
          not use our services for any content that: (i) infringes upon,
          misappropriates, or violates a third party&apos;s intellectual
          property rights or privacy; (ii) is fraudulent, misleading, or
          deceptive; (iii) is defamatory, pornographic, or offensive; (iv)
          promotes discrimination, bigotry, racism, or hatred; (v) promotes
          violence or actions that are threatening to any person or entity; (vi)
          promotes illegal or harmful activities or substances; (vii)
          constitutes spam, such as unauthorized advertising or promotional
          materials, unwanted repetitive or nonsensical content, or anything
          that appears to be a mass solicitation; (viii) contains software
          viruses, phishing links, or other harmful computer code, files, or
          scripts; or (ix) in the sole judgment of ExperienceWarehouse, is
          objectionable or may expose us or our users to any harm or liability
          of any type.
        </p>
        <p>
          (4) You can delete or replace uploaded content at any time. We reserve
          the right to remove any content without warning if we have reason to
          believe that publishing it on our services violates the law, moral
          standards, or the rights of third parties.
        </p>
        <p>
          (5) Content posted on our services may not be copied, disseminated, or
          made publicly accessible without the explicit consent of the rights
          holder, unless permitted by law.
        </p>
        <p>
          (6) Acts of harassment or sexual nature are not permitted on our
          services.
        </p>
        <p>
          (7) Any actions that attack the operational capacity of our services,
          including sending mass emails (spam), attempting to hack or disable
          the security mechanisms of our services, trying to access other user
          accounts, performing brute-force or denial-of-service attacks, using
          or sending spyware, reading data using crawlers or robots, or
          distributing viruses or worms, are strictly prohibited.
        </p>
        <p>
          (8) If you violate these rules, we reserve the right to issue a
          warning, temporarily block your account, or permanently exclude you
          from our services.
        </p>
      </div>
      <br />
      <div>
        <h3>§ 5 Responsibility for Content</h3>
        <p>
          (1) We do not take responsibility for content uploaded by you or other
          users of our services, or for the content of external websites linked
          on our services. We do not guarantee that the content shown on our
          services is true, suitable for a particular purpose, or serves any
          particular purpose. We do not examine the appropriateness or
          virus-free status of content uploaded, nor do we determine whether it
          is technically possible to do so.
        </p>
        <p>
          (2) We are unable to definitively determine the true identity of any
          registered user on our services. We therefore cannot guarantee the
          correct identity of any user.
        </p>
        <p>
          (3) If you notice or suspect any illegal or non-contractual use of our
          services, you can report it to us at any time by emailing{' '}
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>
          .
        </p>
      </div>
      <br />
      <div>
        <h3>§ 6 Indemnification</h3>
        <p>
          (1) You will indemnify us from all claims, including damages claims,
          made by other users or third parties, including public authorities,
          resulting from your use of our services and any violations of their
          rights, particularly due to content you have entered. You will bear
          all appropriate costs, including legal fees, incurred as a result of
          your violations of the rights of third parties. Our other rights and
          damage claims will remain unaffected.
        </p>
        <p>
          (2) This indemnification only applies if you are at fault for the
          violation of rights in question. If the violation is also a breach of
          your duties under this contract, this indemnification will apply
          unless you are not at fault for the violation in question.
        </p>
      </div>
      <br />
      <div>
        <h3>§ 7 Contract Term and Termination</h3>
        <p>(1) This Contract of Use is for an indefinite period of time.</p>
        <p>
          (2)You may terminate this agreement at any time by sending a
          termination notice via email to{' '}
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>
          .com or by using the &quot;delete your account&quot; option in the
          account settings (if available). Upon termination, you will no longer
          have access to your account.
        </p>
        <p>
          (3) We reserve the right to terminate this Contract of Use in text
          form with a notice period of two (2) weeks.
        </p>
        <p>
          (4)In addition, either party may terminate this Contract of Use for
          just cause. We have just cause to terminate this contract,
          particularly if you seriously or repeatedly breach your obligations
          under this Contract of Use, including the rules for using the Services
          outlined in Section 4 above.
        </p>
      </div>
      <br />
      <div>
        <h3>§ 8 Additional Terms for Paid Plans</h3>
        <p>
          The following additional Terms apply to fee-based Paid Plans (cf. § 2
          (3)):
        </p>
        <p>
          If you choose to upgrade from a Free Plan to a Paid Plan, a contract
          will be formed upon completion of the checkout process for the chosen
          Paid Plan and subsequent activation of the Paid Plan by us. It is
          important to note that we reserve the right to refuse activation of a
          Paid Plan or the formation of a contract with you.
        </p>
        <p>
          (2) If you decide to upgrade from a Free Plan to a Paid Plan, the
          contract for the Paid Plan will be concluded when you complete the
          checkout process for the desired Paid Plan and we subsequently
          activate your Paid Plan. We are not obligated to activate your Paid
          Plan or enter into a contract with you. The subscription for Paid
          Plans can be purchased on a monthly or yearly basis. The first payment
          will be due on the day after the conclusion of the contract, and
          subsequent payments will be due one month (for monthly subscriptions)
          or one year (for yearly subscriptions) after the initial payment. The
          price and discounts that are available at the time of purchase are
          outlined on our website at{' '}
          <Link to="/pricing">www.experiencewarehouse.com/pricing</Link>. All
          amounts listed on the website do not include value-added tax (VAT).
        </p>
        <p>
          (3) If you fail to pay for your Paid Plan on time, we reserve the
          right to block access to the Services until payment is received. You
          will still be required to pay the outstanding amount. If you
          significantly default on your payment obligations, we may terminate
          the Contract of Use immediately and claim a lump sum payment equal to
          one quarter of the remaining fees due for the remainder of the
          contract term. The amount of damages may be adjusted if we or you can
          prove that greater or lesser damages have been incurred. We reserve
          the right to pursue further claims for default of payment.
        </p>
        <p>
          We may change the fees for our Paid Plans at any time, provided that
          such changes will not affect any subscription period you have already
          paid for. We will notify you at least 30 days before the change
          becomes effective and you will have the opportunity to cancel your
          subscription before the change becomes effective. If you do not cancel
          your subscription before the change becomes effective, you will be
          deemed to have accepted the new fees. If you have any questions about
          our fees, please contact us at{' '}
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>
          .
        </p>
        <p>
          We reserve the right to assign our rights and obligations under this
          Contract of Use to a third party upon prior notice to you, unless such
          assignment would significantly change your rights or obligations under
          this Contract of Use. You may not assign your rights or obligations
          under this Contract of Use to a third party without our prior written
          consent.
        </p>
      </div>
      <br />
      <div>
        <h3>§ 9 Liability for Defects, Disclaimer</h3>
        <p>
          (1)In addition, we are not liable for any damages caused by the
          Services unless the damages are the result of gross negligence or
          intent on our part. This limitation of liability also applies to the
          employees, representatives, and agents of ExperienceWarehouse.
        </p>
        <p>
          (2)We are not responsible for any damages or defects that may exist in
          the Services at the time of contracting, unless these defects
          significantly impact the intended use of the Services.
        </p>
        <p>
          (3)ExperienceWarehouse is responsible for any damages that result
          from: (i) the intentional or reckless actions or gross negligence of
          ExperienceWarehouse or its legal representatives or agents; (ii) the
          negligent breach of a material contractual duty by ExperienceWarehouse
          or its legal representatives or agents, but only to the extent that
          such damages were foreseeable at the time the Contract of Use was
          concluded; (iii) negligence by ExperienceWarehouse or its legal
          representatives or agents causing injury to life, body, or health; or
          (iv) any legal liability that ExperienceWarehouse or its legal
          representatives or agents are required to accept. A &quot;material
          contractual duty&quot; is a duty whose performance is essential for
          the proper execution of the Contract of Use, or the breach of which
          threatens the attainment of the Contract Purpose, and the observance
          of which the customer can normally rely on.
        </p>
        <p>
          (4) If the customer is found to be partially at fault for the loss of
          data, this will be taken into account. Specifically,
          ExperienceWarehouse will only be responsible for recovering the data
          if the data was saved into backups and make sure that the data can be
          recovered at a reasonable cost from machine-readable sources.
        </p>
        <p>
          (5) This agreement is final and applies to all claims for damages,
          regardless of the legal basis for the claim, including pre-contractual
          and collateral claims. It also applies to the legal representatives
          and agents of ExperienceWarehouse if claims are made directly against
          them.
        </p>
        <p>
          (6) The customer is required to immediately notify ExperienceWarehouse
          of any damages covered by the liability provisions outlined above,
          either in writing or by allowing ExperienceWarehouse to document the
          damages. This is so that ExperienceWarehouse can be informed as soon
          as possible and potentially work with the customer to minimize the
          damages.
        </p>
      </div>
      <br />
      <div>
        <h3>
          § 10 Final Provisions and Amendments to the Terms and Conditions of
          Use
        </h3>
        <p>
          (1) The laws of the United States will apply to this agreement and any
          legal matters that may arise.
        </p>
        <p>
          (2) If you do not have a general place of jurisdiction in United
          States, if you have moved your permanent residence outside of these
          countries after these terms take effect, or if your residence or usual
          place of residence is unknown at the time legal action is taken, the
          exclusive place of jurisdiction for all disputes arising from this
          agreement will be in Brooklyn, New York, United States.
        </p>
        <p>
          (3)Should individual provisions of these Terms be or become invalid
          and/or contrary to statutory provisions, this will not affect the
          validity of the remaining Terms. In lieu of the invalid, unenforceable
          term, the Parties mutually agree on such valid commercial terms which
          the Parties would reasonably have agreed otherwise. The
          above-mentioned provision also applies in case of a lacuna in these
          Terms.
        </p>
        <p>
          (5)We reserve the right to amend and adapt these Terms with effect for
          the future, in particular because of changes in the law, the
          jurisdiction of supreme courts or changes of the market conditions.
          You can request the currently applicable version of the Terms via{' '}
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>{' '}
          or access them at{' '}
          <Link to="/terms">www.experiencewarehouse.com/terms</Link>. You will
          be notified by email no later than one month before new Terms take
          effect. If you do not object to the validity of the new Terms within
          one month after they take effect, you will be deemed to have accepted
          the new Terms. We will inform you accordingly about the significance
          of the one month notice period, your right to object to any amendments
          and the legal consequences of non-objection. This amendment mechanism
          does not apply to amendments to the parties&apos; main contractual
          obligations.
        </p>
      </div>
      <br />
    </section>
  );
}

export default Terms;
