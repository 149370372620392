import axios from 'axios';

const api = {
  createProject: (token) => {
    return axios.post(
      '/api/user/projects/project/',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  deleteProject: (token, projectId) => {
    return axios.delete('/api/user/projects/project/', {
      data: { projectId },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  updateTitle: (token, projectId, title) => {
    return axios.put(
      '/api/user/projects/project/title',
      { projectId, title },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateUrl: (token, projectId, url) => {
    return axios.put(
      '/api/user/projects/project/url',
      { projectId, url },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateResponsibilities: (token, projectId, responsibilities) => {
    return axios.put(
      '/api/user/projects/project/responsibilities',
      { projectId, responsibilities },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  uploadImages: function (token, formData, projectId) {
    return axios.post('/api/user/projects/project/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
        project_id: projectId,
      },
    });
  },
  getSearchedSkills: function (token, searchedSkill) {
    return axios.get('/api/user/projects/project/skills/' + searchedSkill, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    });
  },
  addExistingSkill(token, projectId, skillId) {
    return axios.post(
      '/api/user/projects/project/skill/existing',
      { projectId, skillId },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  addNewSkill(token, projectId, skillName) {
    return axios.post(
      '/api/user/projects/project/skill/new',
      { projectId, skillName },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  toggleProjectActivation(token, projectId, isActive) {
    return axios.put(
      '/api/user/projects/project/activation',
      { projectId, isActive },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },

  deleteProjectSkill(token, projectId, skillId) {
    return axios.delete('/api/user/projects/project/skill/', {
      data: { skillId },
      headers: {
        Authorization: 'Bearer ' + token,
        project_id: projectId,
      },
    });
  },

  deleteProjectImage(token, projectId, imageId) {
    return axios.delete('/api/user/projects/project/image', {
      data: { projectId, imageId },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
