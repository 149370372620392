/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 44px);
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  h1 {
    color: ${({ theme }) => theme.colors.primary.dark};
    font-size: 200px;
    line-height: 200px;
    text-align: center;
  }
  h2 {
    color: ${({ theme }) => theme.colors.primary.dark};
    font-size: 60px;
    line-height: 40px;
    text-align: center;
  }
`;

function NotFound() {
  const { token } = useSelector((state) => state).user;
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    if (token) {
      navigate('user/dashboard');
    } else {
      navigate('/');
    }
  }, [token]);

  return (
    <Wrapper>
      <div>
        <h1>404</h1>
        <h2>Not Found</h2>
      </div>
      <button onClick={handleClick}>Back to home page</button>
    </Wrapper>
  );
}

export default NotFound;
