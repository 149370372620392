/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { userApi } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { INITIALIZE_USER_PROFILE } from 'store/actions';
import { MultiInputForm } from 'components/forms';
import { Paragraph } from 'components/styles';
import * as Yup from 'yup';
import {
  RESET_PASSWORD_STEP,
  LOGIN,
  RESET_PASSWORD,
  SIGNUP,
} from '../authConstants';
const VerifyCode = ({ setApiMessage, authData, setAuthData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    if (authData.type === LOGIN || authData.type === SIGNUP) {
      userApi
        .signUpVerifyCode(authData.email, values.code)
        .then((res) => {
          sessionStorage.removeItem('authData');
          dispatch({
            type: INITIALIZE_USER_PROFILE,
            token: res.data.token,
            user: res.data.user,
          });
          return navigate('/dashboard');
        })
        .catch((err) => {
          setApiMessage(err.response.data);
        });
    } else if (authData.type === RESET_PASSWORD) {
      userApi
        .resetPasswordVerifyCode(authData.email, values.code)
        .then((res) => {
          setAuthData((prev) => {
            setApiMessage({
              type: 'success',
              title: 'Success',
              message: 'Identity Verified',
            });
            let newData = {
              ...prev,
              step: RESET_PASSWORD_STEP,
              token: res.data.token || '',
            };
            sessionStorage.setItem('authData', JSON.stringify(newData));
            return newData;
          });
        })
        .catch((err) => {
          setApiMessage(err?.response?.data);
        });
    }
  };

  const config = {
    inputs: [
      {
        id: 'code',
        initialValue: '',
        label: 'Verification Code',
        type: 'text',
        component: 'input',
        placeholder: 'Verification Code',
        autocomplete: 'Verification Code',
        yupValidation: Yup.string()
          .min(6)
          .max(6)
          .trim()
          .required('No code provided'),
      },
    ],
    handleSubmit,
    submitButtonName: 'Verify',
  };

  return (
    <>
      {authData.type === SIGNUP ? (
        <Paragraph>
          Hi there! To continue, let&apos;s look for the verification code in
          your email inbox!
        </Paragraph>
      ) : (
        <Paragraph>See your email inbox!</Paragraph>
      )}
      <MultiInputForm config={config} />
    </>
  );
};
VerifyCode.propTypes = {
  setApiMessage: PropTypes.func,
  setAuthData: PropTypes.func,
  authData: PropTypes.object,
};

export default VerifyCode;
