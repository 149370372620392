/* eslint-disable indent */
import styled from 'styled-components';

export default styled.section`
  display: flex;

  > * {
    flex: calc(50% - 8px);
  }
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.screenSizes.lg}) {
    > * {
      flex: 100%;
    }
  }
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    justify-content: center;
  }
`;
