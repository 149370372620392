/* eslint-disable no-unused-vars */
import React from 'react';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useActivityPanel } from 'hooks';
import { UPDATE_PROJECT_TITLE } from 'store/actions';
import { projectApi } from 'api';
function SetTitle({ project }) {
  const { addActivity, setLoading } = useActivityPanel();
  const { token } = useSelector((state) => state).user;
  const dispatch = useDispatch();
  function handleSubmit(values) {
    setLoading();
    projectApi
      .updateTitle(token, project.id, values.title)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'Project title was set to ' + values.title,
        });
        dispatch({
          type: UPDATE_PROJECT_TITLE,
          projectId: project.id,
          title: values.title,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="28px"
      inputComponent="input"
      initialValue={project.name}
      inputId="title"
      placeholder="Title"
      validationYup={Yup.string().required('No title provided')}
      handleSubmit={handleSubmit}
    />
  );
}

SetTitle.propTypes = {
  project: PropTypes.object,
};

export default SetTitle;
