/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';

import { AddProjectCard } from './components';

import { MainCard } from 'components/ProjectCards';
import { EditorWrapper } from 'components';
import { Box, CardsContainer } from 'components/styles';
import { useTheme } from 'styled-components';
import { EditableProjectCard } from 'components/ProjectCards';

const Projects = () => {
  const { projects } = useSelector((state) => state).user;
  const theme = useTheme();
  const config = {
    style: {
      maxWidth: '370px',
      background: theme.colors.general.greyLight,
      topBar: {
        width: '100px',
        height: '30px',
      },
    },
    tools: [],
  };
  return (
    <>
      <h2 style={{ margin: '32px 0' }}>Projects</h2>
      <CardsContainer childWidth="180px">
        <EditorWrapper config={config}>
          <AddProjectCard />
        </EditorWrapper>
        {projects.map(
          (
            {
              active,
              id,
              name,
              description,
              responsibilities,
              url,
              images,
              skills,
            },
            i,
          ) => {
            return (
              <EditableProjectCard key={i} id={id} active={active}>
                <MainCard
                  title={name}
                  responsibilities={responsibilities}
                  url={url}
                  description={description}
                  images={images}
                  skills={skills}
                />
              </EditableProjectCard>
            );
          },
        )}
        {[1, 2, 3].map((n) => (
          <Box maxW="370px" h="0" key={n + 'i'}></Box>
        ))}
      </CardsContainer>
    </>
  );
};

export default Projects;
