import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: auto;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary.darker};
  background: white;
  font-size: 25px;
  font-weight: 900;
  font-family: poppins;
  border-radius: 8px;
  ::before {
    content: 'ExperienceWarehouse';
  }
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    ::before {
      content: 'EW';
    }
  }
`;
function Logo() {
  return <Span></Span>;
}
export default Logo;
