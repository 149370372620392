/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Loading } from 'views/landingPages';
import './Project.css';
import {
  EditableProspectCard,
  MainProspectCard,
} from 'components/ProspectCards';
import { SetTitle, SetDescription, SetLink, SetSkills } from './forms';
import { useSelector } from 'react-redux';
import { CardEditorContainer, StepContainer } from 'components/styles';
import { Link } from 'react-router-dom';

const Container = styled.section`
  display: flex;
  align-items: start;
  margin: 32px 0px;
  gap: 24px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.screenSizes.md}) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const ContainerTwo = styled.div`
  position: sticky;
  top: 15px;
  margin-bottom: 30px;
  min-width: 440px;
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    width: 100%;
    min-width: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
  }
`;
const ContainerOne = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 90vh;
`;

const Prospect = () => {
  const { prospects } = useSelector((state) => state).user;

  const editingProspectId = sessionStorage.getItem('editingProspectId');
  const prospect = useMemo(() => {
    return prospects.filter((prospect) => prospect?.id == editingProspectId)[0];
  }, [prospects]);

  if (!prospect?.id) {
    return (
      <ContainerOne>
        <Loading />
        <h2>Sorry, the prospect is not found!</h2>
        <Link to="/prospects">Back to prospects?</Link>
      </ContainerOne>
    );
  }
  return (
    <Container>
      <CardEditorContainer>
        <StepContainer>
          <h3>1. Prospect name</h3>
          <SetTitle prospect={prospect} />
        </StepContainer>
        <StepContainer>
          <h3>2. Useful link</h3>
          <SetLink prospect={prospect} />
        </StepContainer>
        <StepContainer>
          <h3>3. Create memo </h3>
          <SetDescription prospect={prospect} />
        </StepContainer>
        <StepContainer>
          <h3>4. Skills</h3>
          <SetSkills prospect={prospect} />
        </StepContainer>
      </CardEditorContainer>
      <ContainerTwo>
        <EditableProspectCard id={prospect.id} active={prospect.active}>
          <MainProspectCard
            name={prospect.name}
            description={prospect.description}
            url={prospect.url}
            skills={prospect.skills}
            visited={prospect.visited}
            unique_id={prospect.unique_id}
            active={prospect.active}
          />
        </EditableProspectCard>
      </ContainerTwo>
    </Container>
  );
};

export default Prospect;
