/* eslint-disable no-unused-vars */
import React from 'react';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useActivityPanel } from 'hooks';
import { UPDATE_PROSPECT_TITLE } from 'store/actions';
import { prospectApi } from 'api';
function SetTitle({ prospect }) {
  const { addActivity, setLoading } = useActivityPanel();
  const { token } = useSelector((state) => state).user;
  const dispatch = useDispatch();
  function handleSubmit(values) {
    setLoading();
    prospectApi
      .updateTitle(token, prospect.id, values.title)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'Prospect title was set to ' + values.title,
        });
        dispatch({
          type: UPDATE_PROSPECT_TITLE,
          prospectId: prospect.id,
          title: values.title,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="28px"
      inputComponent="input"
      initialValue={prospect.name}
      inputId="title"
      placeholder="company, hiring manager, potential client, etc."
      validationYup={Yup.string().required('No prospect name provided')}
      handleSubmit={handleSubmit}
    />
  );
}

SetTitle.propTypes = {
  prospect: PropTypes.object,
};

export default SetTitle;
