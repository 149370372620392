/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { GoPrimitiveDot } from 'react-icons/go';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  padding: 8px;
  position: relative;
  flex-wrap: wrap;
  box-sizing: content-box;
  h3 {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .indicator {
    position: absolute;
    bottom: -9px;
    right: -10px;
  }
`;
const Body = styled.div``;

function Widget({ link, title, score, icon, children }) {
  const theme = useTheme();
  const color = useMemo(() => {
    if (score >= 75) {
      return theme.colors.general.successLight;
    } else if (score >= 50) {
      return theme.colors.general.warningLight;
    } else {
      return theme.colors.general.errorLight;
    }
  }, [score]);
  return (
    <Wrapper>
      <GoPrimitiveDot className="indicator" size="22px" fill={color} />
      <h3>
        {icon}
        {link ? <Link to={link}>{title}</Link> : { title }}
      </h3>
      <Body>{children}</Body>
    </Wrapper>
  );
}

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  score: PropTypes.number.isRequired,
  link: PropTypes.string,
  icon: PropTypes.any,
};

export default Widget;
