import { useDispatch } from 'react-redux';
import { ADD_LIVE_ACTIVITY, SET_PANEL_STATUS } from 'store/actions';
import { DEFAULT, LOADING } from 'utils/apiIndicatorStatuses';

function useActivityPanel() {
  const dispatch = useDispatch();
  const addActivity = (activity) => {
    if (activity?.type && (activity?.title || activity?.message)) {
      dispatch({ type: ADD_LIVE_ACTIVITY, activity });
    }
  };
  const setLoading = () => {
    dispatch({ type: SET_PANEL_STATUS, status: LOADING });
  };
  const setDefault = () => {
    dispatch({ type: SET_PANEL_STATUS, status: DEFAULT });
  };
  return { addActivity, setLoading, setDefault };
}

export default useActivityPanel;
