/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Card } from 'components/styles';

const MTHead = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 8px;
`;

const MTBody = styled.div`
  padding: 8px;
`;

const MTButton = styled(Button)`
  padding: 6px 16px;
  border: none;
  background: ${({ theme, active }) => {
    return active ? theme.colors.general.white : theme.colors.general.light;
  }};
  color: ${({ theme, active }) => {
    return active ? theme.colors.primary.dark : theme.colors.general.grey;
  }};
`;

function MultiTabContainer({ tabs }) {
  const [activeTab, setActiveTab] = React.useState(null);
  useEffect(() => {
    setActiveTab(tabs.find(({ initActive }) => initActive));
  }, [tabs]);

  return (
    <Card w="100%">
      <MTHead>
        {tabs.map((tab) => {
          return (
            <React.Fragment key={tab.id}>
              {tab.id === activeTab?.id ? (
                <MTButton
                  onClick={() => {
                    setActiveTab(tab);
                    tab.handleClick();
                  }}
                  active={true}
                >
                  {tab.name}
                </MTButton>
              ) : (
                <MTButton
                  onClick={() => {
                    setActiveTab(tab);
                    tab.handleClick();
                  }}
                  active={false}
                >
                  {tab.name}
                </MTButton>
              )}
            </React.Fragment>
          );
        })}
      </MTHead>
      <MTBody>{activeTab?.component}</MTBody>
    </Card>
  );
}

MultiTabContainer.propTypes = {
  tabs: PropTypes.array,
};
export default MultiTabContainer;
