/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Title as CardTitle,
  Link as CardLink,
  Responsibilities,
  Skills,
  Images,
} from '../components';

import styled from 'styled-components';
import { Card } from 'components/styles';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 8px 8px 8px 12px;
  overflow-y: scroll;
  padding-bottom: 230px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DescriptionContainer = styled.div``;

const ProjectCard = ({ title, url, responsibilities, skills, images }) => {
  return (
    <Card h="450px" w="100%">
      <BodyContainer>
        <HeaderContainer>
          <CardTitle title={title} />
          <CardLink link={url} />
        </HeaderContainer>
        <Skills skills={skills} />
        <DescriptionContainer>
          <Responsibilities responsibilities={responsibilities} />
        </DescriptionContainer>
      </BodyContainer>
      <Images images={images} />
    </Card>
  );
};

ProjectCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  responsibilities: PropTypes.string,
  skills: PropTypes.array,
  images: PropTypes.array,
};

export default ProjectCard;
