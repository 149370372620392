import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { userApi } from 'api';
import { ButtonForm, Paragraph } from 'components/styles';
import styled from 'styled-components';
import { VERIFY_CODE_STEP, LOGIN } from '../authConstants';

const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const RadioWrapper = styled.div`
  display: flex;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  label {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const SendCodeForm = ({ setApiMessage, authData, setAuthData }) => {
  return (
    <>
      {authData.type === LOGIN ? (
        <Paragraph>
          Hi friend, to finish registration, let&apos;s verify that you are the
          email owner
        </Paragraph>
      ) : (
        ''
      )}
      <Formik
        initialValues={{ email: authData?.email }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          userApi
            .sendCode(values.email)
            .then((res) => {
              setApiMessage(res.data.apiMessage);
              setAuthData((prev) => {
                let newData = { ...prev, step: VERIFY_CODE_STEP };
                sessionStorage.setItem('authData', JSON.stringify(newData));
                return newData;
              });
            })
            .catch((err) => {
              setApiMessage(err?.response?.data);
            });
        }}
      >
        {({ isSubmitting }) => (
          <CustomForm>
            <RadioWrapper>
              <Field
                id="email"
                type="radio"
                name="email"
                selected
                value={authData?.email}
              />
              <label htmlFor="email">
                <Paragraph m="0">Send code via email</Paragraph>
                <Paragraph m="0">
                  <strong>{authData?.email}</strong>
                </Paragraph>
              </label>
            </RadioWrapper>
            <ButtonForm type="submit" disabled={isSubmitting}>
              Continue
            </ButtonForm>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

SendCodeForm.propTypes = {
  setApiMessage: PropTypes.func,
  setAuthData: PropTypes.func,
  authData: PropTypes.object,
};

export default SendCodeForm;
