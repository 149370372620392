import React from 'react';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import * as Yup from 'yup';
import { useActivityPanel } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_NAME } from 'store/actions';
import { userApi } from 'api';

function SetName() {
  const dispatch = useDispatch();
  const { token, full_name } = useSelector((state) => state).user;
  const { addActivity, setLoading } = useActivityPanel();
  function handleSubmit(values) {
    setLoading();
    userApi
      .updateName(token, values.full_name)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'Account name was set to ' + values.full_name,
        });
        dispatch({
          type: UPDATE_USER_NAME,
          full_name: values.full_name,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="28px"
      inputComponent="input"
      initialValue={full_name}
      inputId="full_name"
      placeholder="Full Name"
      validationYup={Yup.string().trim().required('No Name Provided')}
      handleSubmit={handleSubmit}
    />
  );
}

export default SetName;
