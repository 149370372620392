/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { INITIALIZE_USER_PROFILE } from 'store/actions';
import { FORBIDDEN } from 'utils/apiCodes';
import { Link, useNavigate } from 'react-router-dom';
import { userApi } from 'api';
import * as Yup from 'yup';
import { MultiInputForm } from 'components/forms';
import { Box, ButtonLink } from 'components/styles';

import {
  LOGIN,
  RESET_PASSWORD,
  SEND_CODE_STEP,
  SEARCH_ACCOUNT_STEP,
} from 'views/authPages/Authentication/authConstants';
const Login = ({ setApiMessage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    userApi
      .login(values.email, values.password)
      .then((res) => {
        let { token, user } = res.data;
        dispatch({
          type: INITIALIZE_USER_PROFILE,
          token,
          user,
        });
        navigate('/dashboard');
      })
      .catch((err) => {
        if (err?.response?.status === FORBIDDEN) {
          let userVerification = {
            email: values.email,
            step: SEND_CODE_STEP,
            type: LOGIN,
          };
          sessionStorage.setItem('authData', JSON.stringify(userVerification));
          navigate('/auth');
        }
        setApiMessage(err?.response?.data);
      });
  };

  const config = {
    inputs: [
      {
        id: 'email',
        initialValue: '',
        label: 'Email',
        type: 'text',
        component: 'input',
        placeholder: 'Email',
        autocomplete: 'email',
        yupValidation: Yup.string()
          .trim()
          .email('Email is invalid')
          .required('No email provided'),
      },
      {
        id: 'password',
        initialValue: '',
        label: 'Password',
        type: 'password',
        component: 'input',
        placeholder: 'Password',
        autocomplete: 'current-password',
        yupValidation: Yup.string().trim().required('No password provided'),
      },
    ],
    handleSubmit,
    submitButtonName: 'Log In',
  };
  const handleResetPassword = () => {
    let userVerification = {
      email: '',
      step: SEARCH_ACCOUNT_STEP,
      type: RESET_PASSWORD,
    };
    sessionStorage.setItem('authData', JSON.stringify(userVerification));
    navigate('/auth');
  };
  return (
    <>
      <MultiInputForm config={config} />
      <Box justify="right">
        <ButtonLink onClick={handleResetPassword}>
          Issues getting in?
        </ButtonLink>
      </Box>
    </>
  );
};

Login.propTypes = {
  setApiMessage: PropTypes.func,
};
export default Login;
