import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Stacked = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={150}>
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 27,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" scale="band" />
        <Tooltip />
        <Legend />
        <Bar dataKey="active" stackId="a" barSize={20} fill="green" />
        <Bar
          dataKey="inactive"
          stackId="a"
          barSize={20}
          fill="gray"
          label={{ position: 'right' }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

Stacked.propTypes = {
  data: PropTypes.array,
};

export default Stacked;
