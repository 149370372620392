/* eslint-disable indent */
import styled, { keyframes } from 'styled-components';
import { RiLoader2Fill } from 'react-icons/ri';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export default styled(RiLoader2Fill)`
  animation: ${rotate} 1s ease-in-out infinite;
  color: ${({ theme }) => {
    return theme.colors.primary.mediumDark || 'black';
  }};
`;
