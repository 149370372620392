/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IconButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0px 4px;
  margin: ${({ m }) => m};
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }

  &:hover svg,
  &:focus svg,
  &:active svg {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
  svg {
    margin: ${({ iM }) => iM};
    color: ${({ iC }) => iC};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: none;
  width: 100%;
  max-width: ${({ maxW }) => maxW};
  min-width: ${({ minW }) => minW || '280px'};
  gap: 2px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ bg }) => bg};
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  width: ${({ w }) => w};
  height: ${({ h }) => h};
`;
const Bottom = styled.div`
  background: ${({ bg }) => bg};
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  padding: 2px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
`;

const EditorWrapper = ({ config, children }) => {
  return (
    <Wrapper maxW={config?.style.maxWidth} minW={config?.style.minWidth}>
      <Top
        w={config?.style.topBar.width}
        h={config?.style.topBar.height}
        bg={config?.style.background}
      >
        {config?.tools.map(({ name, icon: Icon, action, style }, i) => {
          return (
            <IconButton
              key={i}
              onClick={action}
              aria-label={name}
              iM={style?.icon?.margin}
              iC={style?.icon?.color}
            >
              <Icon size={style?.icon?.size} />
            </IconButton>
          );
        })}
      </Top>
      <Bottom bg={config?.style.background}>{children}</Bottom>
    </Wrapper>
  );
};
EditorWrapper.propTypes = {
  config: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};
export default EditorWrapper;
