/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { INITIALIZE_USER_PROFILE } from 'store/actions';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { userApi } from 'api';
import { MultiInputForm } from 'components/forms';

const ResetPasswordForm = ({ setApiMessage, authData, setAuthData }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    userApi
      .setNewPassword(authData.token, values.password)
      .then((res) => {
        dispatch({
          type: INITIALIZE_USER_PROFILE,
          token: authData.token,
          user: res.data.user,
        });
      })
      .catch((err) => {
        setApiMessage(err?.response?.data);
      });
  };

  const config = {
    inputs: [
      {
        id: 'email',
        initialValue: '',
        label: 'Username',
        type: 'text',
        component: 'input',
        placeholder: 'Email',
        autocomplete: 'email',
        yupValidation: Yup.string(),
        isHidden: true,
      },
      {
        id: 'password',
        initialValue: '',
        label: 'New Password',
        type: 'password',
        component: 'input',
        placeholder: 'New Password',
        autocomplete: 'new-password',
        yupValidation: Yup.string()
          .min(8, 'Password must be at least 8 characters long')
          .max(64, 'Password must be no more than 64 characters long')
          .matches(
            /[a-z]/,
            'Password must contain at least one lowercase letter',
          )
          .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase letter',
          )
          .matches(/\d/, 'Password must contain at least one number')
          .required('New password is required in order to continue'),
      },
    ],
    handleSubmit,
    submitButtonName: 'Set New Password',
  };

  return <MultiInputForm config={config} />;
};

ResetPasswordForm.propTypes = {
  setApiMessage: PropTypes.func,
  setAuthData: PropTypes.func,
  authData: PropTypes.object,
};
export default ResetPasswordForm;
