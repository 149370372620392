/* eslint-disable indent */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import {
  // ACTIVITY PANEL
  SET_ACTIVITY_PANEL_ACTIVE,
  SET_ACTIVITY_PANEL_INACTIVE,
  SET_PANEL_STATUS,
  ADD_LIVE_ACTIVITY,
  REMOVE_LIVE_ACTIVITY,
  REMOVE_LIVE_ACTIVITIES,
  // USER
  UPDATE_USER_NAME,
  UPDATE_USER_JOB,
  UPDATE_USER_ABOUT,
  ADD_USER_IMAGES,
  SET_PROFILE_IMAGE,
  // PROJECTS
  CREATE_PROJECT,
  UPDATE_PROJECT_TITLE,
  UPDATE_PROJECT_LINK,
  ADD_PROJECT_SKILL,
  DELETE_PROJECT,
  DELETE_PROJECT_SKILL,
  ADD_IMAGES,
  UPDATE_PROJECT_RESPONSIBILITIES,
  DELETE_PROJECT_IMAGE,
  TOGGLE_PROJECT,
  // PROSPECTS
  CREATE_PROSPECT,
  DELETE_PROSPECT,
  ACTIVATE_PROSPECT,
  UPDATE_PROSPECT_TITLE,
  UPDATE_PROSPECT_LINK,
  UPDATE_PROSPECT_DESCRIPTION,
  DELETE_PROSPECT_SKILL,
  ADD_PROSPECT_SKILL,
  TOGGLE_PROSPECT,

  //NAVIGATION
  SET_LAYOUT_STATE,
  CHANGE_SIDEBAR_CHANGE,

  // GENERAL
  INITIALIZE_USER_PROFILE,
  LOG_OFF,
} from './actions';

import {
  LOADING,
  SUCCESS,
  ERROR,
  WARNING,
  DEFAULT,
} from 'utils/apiIndicatorStatuses';
import { SIDEBAR, ACTIVITY_PANEL, MENU, CLOSED_ALL } from 'utils/topBarStates';

import { isTokenExpired, getToken } from 'utils';
const token = getToken();
const initialState = {
  user: {
    projects: [],
    prospects: [],
    images: [],
    apiMessage: [{ type: '', title: '', body: '' }],
    token: isTokenExpired(token) ? null : token,
    email: '',
    username: '',
    full_name: '',
    profile_image: '',
    job: '',
    about: '',
    verified: null,
  },
  activityPanel: {
    isActive: false,
    status: DEFAULT,
    activities: [],
    notifications: [],
  },
  layout: {
    isSidebarActive: true,
    active: CLOSED_ALL,
  },
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          projects: action.user.projects || [],
          prospects: action.user.prospects || [],
          images: action.user.images || [],
          token: action.token || getToken() || null,
          email: action.user.email || '',
          username: action.user.username || '',
          full_name: action.user.full_name || '',
          job: action.user.job || '',
          profile_image: action.user.profile_image || '',
          about: action.user.about || '',
          verified: action.user.verified || null,
        },
      };
    case CREATE_PROJECT:
      return {
        ...state,
        user: {
          ...state.user,
          projects: [...action.projects],
          apiMessage: [action.apiMessage],
        },
      };
    case UPDATE_PROJECT_TITLE:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id == action.projectId
              ? { ...project, name: action.title }
              : project;
          }),
        },
      };
    case UPDATE_PROJECT_LINK:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id == action.projectId
              ? { ...project, url: action.url }
              : project;
          }),
        },
      };
    case UPDATE_PROJECT_RESPONSIBILITIES:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id == action.projectId
              ? { ...project, responsibilities: action.responsibilities }
              : project;
          }),
        },
      };
    case TOGGLE_PROJECT:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id == action.projectId
              ? { ...project, active: action.isActive }
              : project;
          }),
        },
      };
    case DELETE_PROJECT:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.filter(
            (project) => project.id !== action.projectId,
          ),
        },
      };
    case DELETE_PROJECT_SKILL:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id == action.projectId
              ? {
                  ...project,
                  skills: project.skills.filter(
                    (skill) => skill.id !== action.skillId,
                  ),
                }
              : project;
          }),
        },
      };
    case DELETE_PROJECT_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id === action.projectId
              ? {
                  ...project,
                  images: project.images.filter(
                    (image) => image.id !== action.imageId,
                  ),
                }
              : project;
          }),
        },
      };

    case ADD_IMAGES:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id === action.projectId
              ? {
                  ...project,
                  images: [...project.images, ...action.newImages],
                }
              : project;
          }),
        },
      };

    case ADD_PROJECT_SKILL:
      return {
        ...state,
        user: {
          ...state.user,
          projects: state.user.projects.map((project) => {
            return project.id == action.projectId
              ? {
                  ...project,
                  skills: [
                    ...project.skills,
                    { skill: action.skill, id: action.id },
                  ],
                }
              : project;
          }),
        },
      };
    case CREATE_PROSPECT:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: [...action.prospects],
        },
      };
    case DELETE_PROSPECT:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.filter(
            (prospect) => prospect.id !== action.prospectId,
          ),
        },
      };
    case UPDATE_PROSPECT_TITLE:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.map((prospect) => {
            return prospect.id == action.prospectId
              ? { ...prospect, name: action.title }
              : prospect;
          }),
        },
      };
    case TOGGLE_PROSPECT:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.map((prospect) => {
            return prospect.id == action.prospectId
              ? { ...prospect, active: action.isActive }
              : prospect;
          }),
        },
      };
    case UPDATE_PROSPECT_LINK:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.map((prospect) => {
            return prospect.id == action.prospectId
              ? { ...prospect, url: action.url }
              : prospect;
          }),
        },
      };
    case UPDATE_PROSPECT_DESCRIPTION:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.map((prospect) => {
            return prospect.id == action.prospectId
              ? { ...prospect, description: action.description }
              : prospect;
          }),
        },
      };
    case DELETE_PROSPECT_SKILL:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.map((prospect) => {
            return prospect.id == action.prospectId
              ? {
                  ...prospect,
                  skills: prospect.skills.filter(
                    (skill) => skill.id !== action.skillId,
                  ),
                }
              : prospect;
          }),
        },
      };
    case ADD_PROSPECT_SKILL:
      return {
        ...state,
        user: {
          ...state.user,
          prospects: state.user.prospects.map((prospect) => {
            return prospect.id == action.prospectId
              ? {
                  ...prospect,
                  skills: [
                    ...prospect.skills,
                    { skill: action.skill, id: action.id },
                  ],
                }
              : prospect;
          }),
        },
      };
    case SET_ACTIVITY_PANEL_INACTIVE:
      return {
        ...state,
        activityPanel: { ...state.activityPanel, isActive: false },
      };
    case SET_ACTIVITY_PANEL_ACTIVE:
      return {
        ...state,
        activityPanel: { ...state.activityPanel, isActive: true },
      };
    case SET_PANEL_STATUS:
      return {
        ...state,
        activityPanel: { ...state.activityPanel, status: action.status },
      };

    case ADD_LIVE_ACTIVITY:
      return {
        ...state,
        activityPanel: {
          ...state.activityPanel,
          activities: [
            ...state.activityPanel.activities,
            {
              ...action.activity,
              id: state.activityPanel.activities.length,
            },
          ],
        },
      };
    case REMOVE_LIVE_ACTIVITY:
      return {
        ...state,
        activityPanel: {
          ...state.activityPanel,
          activities: state.activityPanel.activities.filter(
            (activity) => activity.id !== action.activityId,
          ),
        },
      };
    case REMOVE_LIVE_ACTIVITIES:
      return {
        ...state,
        activityPanel: {
          ...state.activityPanel,
          activities: [],
        },
      };
    case UPDATE_USER_NAME:
      return { ...state, user: { ...state.user, full_name: action.full_name } };
    case UPDATE_USER_JOB:
      return { ...state, user: { ...state.user, job: action.job } };
    case UPDATE_USER_ABOUT:
      return { ...state, user: { ...state.user, about: action.about } };
    case SET_LAYOUT_STATE:
      return { ...state, layout: { ...state.layout, active: action.active } };
    case CHANGE_SIDEBAR_CHANGE:
      return {
        ...state,
        layout: {
          ...state.layout,
          isSidebarActive: !state.layout.isSidebarActive,
        },
      };
    case ADD_USER_IMAGES:
      return {
        ...state,
        user: {
          ...state.user,
          images: action.newImages,
        },
      };
    case SET_PROFILE_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          profile_image: action.profile_image,
        },
      };
    case LOG_OFF:
      return { ...state, user: initialState.user };
    default:
      return state;
  }
};

export default reducers;
