/* eslint-disable quotes */
export default {
  //https://www.canva.com/colors/color-meanings/purple/
  colors: {
    primary: {
      darker: '#51087E',
      dark: '#6C0BA9',
      mediumDark: '#880ED4',
      medium: '#A020F0',
      mediumLight: '#B24BF3',
      light: '#C576F6',
      lighter: '#D7A1F9',
    },
    general: {
      important: '#007bff',
      info: '#3d7782',
      infoLight: '#d1ecf1',
      success: '#2d683c',
      successLight: '#d4edda',
      warning: '#987b2d',
      warningLight: '#fff3cc',
      error: '#8b4148',
      errorLight: '#f8d7da',
      black: '#343a40',
      grey: 'grey',
      greyLight: '#F8F8F8',
      light: '#f5f5fa',
      lighter: '#f8f8ff',
      white: '#fff',
    },
    text: {
      primary: '#2a1f62',
    },
  },
  fonts: {
    body: "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');",
    baseSize: '14px',
  },

  layout: {
    barHeight: '40px',
    cardWidth: '300px',
    cardHeight: '450px',
  },
  screenSizes: {
    xs: '600px',
    sm: '800px',
    md: '1000px',
    lg: '1200px',
    xl: '1400px',
  },
  general: {
    borderRadius: {
      small: '4px',
      medium: '8px',
      large: '16px',
      xLarge: '32px',
    },
    shadows: {
      primary: '0 11px 34px 0 rgb(0 0 0 / 15%);',
      small: '0 11px 34px 0 rgb(0 0 0 / 10%);',
    },
    gradients: {
      primary: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
    },
  },
};
