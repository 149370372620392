/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/styles';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
const SkillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Top = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.general.light};
`;
const TopBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 8px;
  gap: 8px;
`;
const Bottom = styled.div`
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  min-height: auto:
  height: fit-content;
  position: relative;
  form {
    display: flex;
    flew-wrap: nowrap;
    align-items: center;
  }

  input {
    width: calc(100% - 34px);
    padding: 0 16px;
    border-radius: 16px 0 0 16px;
    height: 32px;
    border: 2px solid ${({ theme }) => theme.colors.general.light};
    :focus {
      outline: none;
    }
  }
  .search {
    height: 36px;
    border: 2px solid ${({ theme }) => theme.colors.general.light};
    background: white;
    color: black;
    border-radius: 0 16px 16px 0;
    border-left: 0;
  }
  .close {
    position: absolute;
    right: 88px;
    margin-bottom: -5px;
  }

  p {
    text-align: center;
  }
`;

const SkillButton = styled.button`
  padding: 2px 4px;
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  background: white;
  background: ${({ theme, active }) => {
    return active ? theme.colors.general.successLight : 'white';
  }};
  color: ${({ theme }) => theme.colors.primary.darker};

  :active,
  :focus,
  :hover {
    background: ${({ theme, active }) =>
      !active ? 'white' : theme.colors.general.successLight};
  }
`;
const UtilsButton = styled.button`
  padding: 0 2px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  background: white;
  color: ${({ theme }) => theme.colors.primary.dark};

  :active,
  :focus,
  :hover {
    color: ${({ theme }) => theme.colors.primary.medium};
  }
  :active svg,
  :focus svg,
  :hover svg {
    color: ${({ theme }) => theme.colors.primary.medium};
  }
`;

function ProspectSearchBar({
  handleSearch,
  distinctUserSkills,
  prospectSkills,
  handleProspectSkillClick,
  totalProjectsNumber,
  matchingProjectsNumber,
  resetActiveSkills,
  clearActiveSkills,
}) {
  const [inputValue, setInputValue] = useState('');
  return (
    <Card w="100%" maxW="550px">
      <Top>
        <SkillsWrapper>
          {Object.entries(distinctUserSkills).map(([key, value]) => {
            return (
              <SkillButton
                key={key}
                active={prospectSkills[key] ? true : false}
                onClick={() => {
                  handleProspectSkillClick(key, value);
                }}
              >
                {value}
              </SkillButton>
            );
          })}
        </SkillsWrapper>
        <TopBottom>
          <UtilsButton onClick={resetActiveSkills}>reset</UtilsButton>
          <UtilsButton onClick={clearActiveSkills}>clear</UtilsButton>
        </TopBottom>
      </Top>
      <Bottom>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(inputValue);
          }}
        >
          <input
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            value={inputValue}
            placeholder="search"
          ></input>
          <button className="search" type="submit">
            Search
          </button>
          {inputValue ? (
            <UtilsButton
              className="close"
              aria-label="clear search input"
              onClick={() => {
                setInputValue('');
                handleSearch('');
              }}
            >
              <AiOutlineClose />
            </UtilsButton>
          ) : (
            ''
          )}
        </form>
        <p>
          {matchingProjectsNumber} out of {totalProjectsNumber} results
        </p>
      </Bottom>
    </Card>
  );
}

ProspectSearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  distinctUserSkills: PropTypes.object,
  prospectSkills: PropTypes.object,
  handleProspectSkillClick: PropTypes.func,
  totalProjectsNumber: PropTypes.number,
  matchingProjectsNumber: PropTypes.number,
  resetActiveSkills: PropTypes.func,
  clearActiveSkills: PropTypes.func,
};

export default ProspectSearchBar;
