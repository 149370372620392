import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const DescriptionSchema = Yup.object().shape({
  description: Yup.string().required('No title provided'),
});
const SetDescription = ({ description, setCardState }) => {
  const [isSaved, setIsSaved] = useState(true);
  function handleChange(descriptionUpdated) {
    if (description !== descriptionUpdated) {
      return setIsSaved(false);
    }
    return setIsSaved(true);
  }
  return (
    <>
      <Formik
        initialValues={{ description: description }}
        validationSchema={DescriptionSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          //save in database.
          //when success update state
          setCardState((prev) => {
            return { ...prev, description: values.description };
          });
        }}
      >
        {({ isSubmitting, errors, values, touched }) => {
          handleChange(values.description);
          return (
            <Form className="d-flex">
              <div className="w-100">
                <label className="d-none" htmlFor="title" />
                <Field
                  className={
                    errors.email && touched.email
                      ? 'w-100 input-error'
                      : 'w-100'
                  }
                  type="textarea"
                  id="description"
                  name="description"
                  placeholder="Description"
                />
                <ErrorMessage
                  className="field-error"
                  name="description"
                  component="div"
                />
              </div>
              <button
                className="btn-unsaved"
                type="submit"
                disabled={isSubmitting}
              >
                {isSaved ? 'Saved' : 'Save'}
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

SetDescription.propTypes = {
  description: PropTypes.string,
  setCardState: PropTypes.func,
};

export default SetDescription;
