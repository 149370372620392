/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 4px 8px;
  background: ${({ theme, type }) => theme.colors.general[type + 'Light']};
`;

const TopMessage = ({ type, children }) => {
  return <Container type={type}>{children}</Container>;
};

TopMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  children: PropTypes.object,
};

export default TopMessage;
