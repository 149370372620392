import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: ${({ direction }) => {
    return direction || 'row';
  }};
  flex-wrap: ${({ wrap }) => {
    return wrap || 'wrap';
  }};
  position: ${({ position }) => {
    return position || 'relative';
  }};
  overflow: ${({ overflow }) => {
    return overflow || 'visible';
  }};
  min-width: ${({ minW }) => {
    return minW || 'auto';
  }};
  max-width: ${({ maxW }) => {
    return maxW || 'auto';
  }};
  width: ${({ w }) => {
    return w || 'auto';
  }};
  height: ${({ h }) => {
    return h || 'auto';
  }};
  min-height: ${({ minH }) => {
    return minH || 'auto';
  }};
  max-height: ${({ maxH }) => {
    return maxH || 'auto';
  }};
  box-shadow: ${({ boxShadow }) => {
    return boxShadow || '0';
  }};
  border: ${({ b }) => {
    return b || '0';
  }};
  border-left: ${({ bl, b }) => {
    return b || bl || '0';
  }};
  border-radius: ${({ r }) => {
    return r || '0';
  }};
  background: ${({ bg }) => {
    return bg || 'transparent';
  }};
  gap: ${({ gap }) => {
    return gap || '0';
  }};
  justify-content: ${({ justify }) => {
    return justify || 'left';
  }};
  align-items: ${({ align }) => {
    return align || 'top';
  }};
  box-sizing: border-box;
  padding: ${({ p }) => {
    return p || '0';
  }};
  margin: ${({ m }) => {
    return m || '0';
  }};
  color: ${({ c }) => {
    return c || 'inherit';
  }};
`;
