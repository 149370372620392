import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Skill = styled.span`
  padding: 2px 4px;
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  background: white;
  background: ${({ theme, active }) => {
    return active ? theme.colors.general.successLight : 'white';
  }};
  color: ${({ theme }) => theme.colors.general.black};
`;
const TypeOne = ({ name, active }) => {
  return <Skill active={active}>{name}</Skill>;
};

TypeOne.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
};

export default TypeOne;
