import styled from 'styled-components';

export default styled.button`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary.dark};
  background: ${({ theme }) => theme.colors.general.white};
  padding: 8px;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.general.light};
    outline: none;
  }
  &:hover:disabled,
  &:focus:disabled,
  &:active:disabled {
    background: ${({ theme }) => theme.colors.general.light};
    outline: none;
    opacity: 0.5;
  }
`;
