import axios from 'axios';

const api = {
  getPortfolioItems: function (token, prospectId) {
    return axios.post(
      `/api/portfolio/${prospectId}`,
      { prospectUniqueId: prospectId },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateSession: function (id) {
    return axios.put('/api/portfolio/session', { id });
  },
};

export default api;
