import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { EditorWrapper } from 'components';
import { AddImagesDropzone } from '..';

const AddImagesDropzoneWrapped = ({ projectId }) => {
  const theme = useTheme();
  const config = {
    style: {
      maxWidth: '170px',
      minWidth: 'fit-content',
      background: theme.colors.general.greyLight,
      topBar: {
        width: '30px',
        height: '30px',
      },
    },
    tools: [],
  };
  return (
    <EditorWrapper config={config}>
      <AddImagesDropzone projectId={projectId} />
    </EditorWrapper>
  );
};

AddImagesDropzoneWrapped.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default AddImagesDropzoneWrapped;
