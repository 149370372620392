import React from 'react';
import { Feature } from '../components';
import { CardsContainer } from 'components/styles';

const features = [
  {
    title: 'Document projects',
    text: 'From any device, any time, securely add projects you worked on',
  },
  {
    title: 'Document job applications',
    text: 'Record applied jobs, along with relevant skills, links, contacts, and notes',
  },
  {
    title: 'Share portfolio',
    text: 'After documenting a job application, share generated link',
  },
  {
    title: 'Granular Control',
    text: 'Enable or disable access to projects and portfolio',
  },
  {
    title: 'Focus on relevant projects',
    text: 'Shared portfolio highlights projects based on applied job and your skills',
  },
  {
    title: 'Statistics',
    text: 'Get insights about your projects and applied job applications',
  },
  {
    title: 'Win-Win',
    text: 'Efficient portfolio navigation helps employers to focus on key projects',
  },
  {
    title: 'Feedback',
    text: 'Experience Warehouse is committed to learn and create positive impact',
  },
];

function Home() {
  return (
    <section>
      <h2 style={{ textAlign: 'center', margin: '16px' }}>Features</h2>
      <CardsContainer>
        {features.map(({ title, text }, i) => {
          return <Feature key={i} title={title} text={text} />;
        })}
        {[1, 2, 3].map((n) => {
          return <div key={n} style={{ maxWidth: '370px' }}></div>;
        })}
      </CardsContainer>
    </section>
  );
}

export default Home;
