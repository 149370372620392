/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SET_PROFILE_IMAGE } from 'store/actions';
import { BiImageAdd } from 'react-icons/bi';
import { userApi } from 'api';
import { useDropzone } from 'react-dropzone';
import { useActivityPanel } from 'hooks';
import styled from 'styled-components';
const Container = styled.div`
  width: 180px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  overflow: hidden;

  &:focus svg,
  &:hover svg,
  &:active svg {
    fill: ${({ theme }) => theme.colors.general.success};
  }
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const SetImages = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { addActivity, setLoading } = useActivityPanel();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (acceptedFiles.length) {
      uploadImages(acceptedFiles);
    }
  }, [acceptedFiles]);
  const uploadImages = function (acceptedFiles, projectId) {
    let fd = new FormData();
    fd.append('data', projectId);
    acceptedFiles.forEach((file) => {
      fd.append('images', file, file.name);
    });

    userApi
      .uploadImages(user.token, fd)
      .then((res) => {
        addActivity({
          type: 'success',
          message: 'Profile image was updated!',
        });
        dispatch({
          type: SET_PROFILE_IMAGE,
          profile_image: res.data.profile_image,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  };

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <BiImageAdd size="30px" color="rgba(0,0,0,0.4)" />
    </Container>
  );
};

export default SetImages;
