import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_PROSPECT_SKILL } from 'store/actions';
import { prospectApi } from 'api';
import { useActivityPanel } from 'hooks';

import { EditableSkillTag } from 'components/Skill';
import { SetSkill } from '../';
import { Box } from 'components/styles';

const SetSkills = ({ prospect }) => {
  const { user } = useSelector((state) => state);
  const { addActivity, setLoading } = useActivityPanel();
  const dispatch = useDispatch();
  const deleteSkill = (token, prospectId, skillId) => {
    setLoading();
    prospectApi
      .deleteProspectSkill(token, prospectId, skillId)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'A skill was deleted!',
        });
        dispatch({
          type: DELETE_PROSPECT_SKILL,
          prospectId,
          skillId,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  };
  return (
    <Box gap="4px">
      {prospect?.skills?.map(({ skill, id }) => {
        return (
          <EditableSkillTag
            key={id}
            name={skill}
            handleDelete={() => deleteSkill(user.token, prospect.id, id)}
          />
        );
      })}
      <SetSkill prospectId={prospect.id} />
    </Box>
  );
};

SetSkills.propTypes = {
  prospect: PropTypes.object,
};

export default SetSkills;
