/* eslint-disable no-unused-vars */
import React from 'react';
import { feedbackApi } from 'api';
import * as Yup from 'yup';
import { MultiInputForm } from 'components/forms';
import { useApiMessage } from 'hooks';
import { Box } from 'components/styles';
import { useSelector } from 'react-redux';

const Feedback = () => {
  const { user } = useSelector((state) => state);
  const [ApiMessage, setApiMessage] = useApiMessage();
  const handleSubmit = (values) => {
    feedbackApi
      .submitFeedback(
        user.username,
        values.email,
        values.title,
        values.description,
      )
      .then((res) => {
        console.log(res);
        setApiMessage(res.data);
      })
      .catch((err) => {
        setApiMessage(err?.response?.data);
      });
  };

  const config = {
    inputs: [
      {
        id: 'email',
        initialValue: user.email || '',
        label: 'Your Email',
        type: 'text',
        component: 'input',
        placeholder: 'Your Email',
        autocomplete: 'email',
        yupValidation: Yup.string()
          .trim()
          .email('Email is invalid')
          .required('No email provided'),
      },
      {
        id: 'title',
        initialValue: '',
        label: 'Subject',
        type: 'text',
        component: 'input',
        placeholder: 'Subject',
        autocomplete: 'title',
        yupValidation: Yup.string().trim().required('No subject provided'),
      },
      {
        id: 'description',
        initialValue: '',
        label: 'Message',
        type: 'text',
        component: 'textarea',
        placeholder: 'Message',
        autocomplete: 'description',
        yupValidation: Yup.string().trim().required('No message provided'),
      },
    ],
    handleSubmit,
    submitButtonName: 'Submit',
  };

  return (
    <Box direction="column" w="100%">
      <h2 style={{ margin: '32px 0' }}>Feedback</h2>
      <Box maxW="370px" w="100%" m="0 auto" gap="8px">
        <ApiMessage />
        <MultiInputForm config={config} />
      </Box>
    </Box>
  );
};

export default Feedback;
