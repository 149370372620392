/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_IMAGES } from 'store/actions';
import { BiImageAdd } from 'react-icons/bi';
import { projectApi } from 'api';
import { useDropzone } from 'react-dropzone';
import { useActivityPanel } from 'hooks';
import styled from 'styled-components';

const Container = styled.div`
  width: 180px;
  background-color: white;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  overflow: hidden;

  &:focus svg,
  &:hover svg,
  &:active svg {
    fill: ${({ theme }) => theme.colors.general.success};
  }
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const AddImages = ({ projectId }) => {
  const { addActivity, setLoading } = useActivityPanel();
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setLoading();
      const imageFiles = acceptedFiles.filter((file) => {
        if (!file.type.startsWith('image/')) {
          addActivity({
            type: 'error',
            title: 'File upload error',
            message: (
              <div>
                <strong>{file.name}</strong> was not uploaded. Just image types
                are allowed!
              </div>
            ),
          });
          return false;
        }
        return true;
      });

      if (imageFiles.length) {
        uploadImages(imageFiles, projectId);
      }
    },
  });

  const uploadImages = function (acceptedFiles, projectId) {
    let fd = new FormData();
    acceptedFiles.forEach((file) => {
      fd.append('images', file, file.name);
    });
    projectApi
      .uploadImages(user.token, fd, projectId)
      .then((res) => {
        res.data.messages.forEach((message) => {
          addActivity(message);
        });

        dispatch({
          type: ADD_IMAGES,
          projectId: projectId,
          newImages: res.data.images,
        });
      })
      .catch((err) => {
        addActivity(
          err?.response?.data || {
            type: 'error',
            message: 'There been an issue loading images',
          },
        );
      });
  };

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <BiImageAdd size="30px" color="rgba(0,0,0,0.4)" />
    </Container>
  );
};

AddImages.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default AddImages;
