import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function SubProcesses() {
  return (
    <Wrapper>
      <br />
      <h1>SubProcesses</h1>
      <br />
      <p>
        In effect as of <i>January 6, 2023</i>
      </p>
      <br />
      <div>
        <h2>Infrastructure SubProcessors</h2>
        <p>
          We use the following SubProcessors to host customer data or provide
          other infrastructure that helps with the delivery of our Services:
        </p>

        <table>
          <tr>
            <th>Entity Name</th>
            <th>SubProcessing Activities</th>
            <th>Entity Country</th>
            <th>Data Location</th>
          </tr>
          <tr>
            <td>Amazon Web Services</td>
            <td>Cloud Service Provider</td>
            <td>United States</td>
            <td>United States</td>
          </tr>
          <tr>
            <td>Heroku, Inc.</td>
            <td>Cloud Service Provider</td>
            <td>United States</td>
            <td>United States</td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <div>
        <h2>Other SubProcessors</h2>
        <p>
          ExperienceWarehouse uses the following SubProcessors to perform other
          Service functions:
        </p>
        <table>
          <tr>
            <th>Entity Name</th>
            <th>SubProcessing Activities</th>
            <th>Entity Country</th>
            <th>Data Location</th>
          </tr>
          <tr>
            <td>Google LLC</td>
            <td>Mail Service Provider</td>
            <td>United States</td>
            <td>United States</td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <div>
        <h2>Updates</h2>
        <p>
          We may use different SubProcessors as our business develops and
          changes. Be sure to check this page frequently for updates.
        </p>
      </div>
    </Wrapper>
  );
}

export default SubProcesses;
