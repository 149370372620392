export const INITIALIZE_USER_PROFILE = 'INITIALIZE_USER_PROFILE';
export const DELETE_PROJECT_SKILL = 'DELETE_PROJECT_SKILL';
export const DELETE_PROJECT_IMAGE = 'DELETE_PROJECT_IMAGE';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT_TITLE = 'UPDATE_PROJECT_TITLE';
export const UPDATE_PROJECT_LINK = 'UPDATE_PROJECT_LINK';
export const ADD_PROJECT_SKILL = 'ADD_PROJECT_SKILL';
export const UPDATE_PROJECT_RESPONSIBILITIES =
  'UPDATE_PROJECT_RESPONSIBILITIES';
export const UPDATE_PROJECT_IMAGES = 'UPDATE_PROJECT_IMAGES';
export const TOGGLE_PROJECT = 'TOGGLE_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_ACTIVITY_INDICATOR_STATUS =
  'UPDATE_ACTIVITY_INDICATOR_STATUS';
export const ADD_IMAGES = 'ADD_IMAGES';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_USER_JOB = 'UPDATE_USER_JOB';
export const UPDATE_USER_ABOUT = 'UPDATE_USER_ABOUT';
export const ADD_USER_IMAGES = 'ADD_USER_IMAGES';
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const LOG_OFF = 'LOG_OFF';

// ACTIVITY PANEL
export const SET_ACTIVITY_PANEL_ACTIVE = 'SET_ACTIVITY_PANEL_ACTIVE';
export const SET_ACTIVITY_PANEL_INACTIVE = 'SET_ACTIVITY_PANEL_INACTIVE';
export const SET_PANEL_STATUS = 'SET_PANEL_STATUS';
export const ADD_LIVE_ACTIVITY = 'ADD_LIVE_ACTIVITY';
export const REMOVE_LIVE_ACTIVITY = 'REMOVE_LIVE_ACTIVITY';
export const REMOVE_LIVE_ACTIVITIES = 'REMOVE_LIVE_ACTIVITIES';

// PROSPECT ACTIONS
export const CREATE_PROSPECT = 'CREATE_PROSPECT';
export const DELETE_PROSPECT = 'DELETE_PROSPECT';
export const ACTIVATE_PROSPECT = 'ACTIVATE_PROSPECT';
export const UPDATE_PROSPECT_TITLE = 'UPDATE_PROSPECT_TITLE';
export const DELETE_PROSPECT_SKILL = 'DELETE_PROSPECT_SKILL';
export const UPDATE_PROSPECT_LINK = 'UPDATE_PROSPECT_LINK';
export const ADD_PROSPECT_SKILL = 'ADD_PROSPECT_SKILL';
export const UPDATE_PROSPECT_DESCRIPTION = 'UPDATE_PROSPECT_DESCRIPTION';
export const TOGGLE_PROSPECT = 'TOGGLE_PROSPECT';

//NAVIGATION
export const SET_LAYOUT_STATE = 'SET_LAYOUT_STATE';
export const CHANGE_SIDEBAR_CHANGE = 'CHANGE_SIDEBAR_CHANGE';
