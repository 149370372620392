/* eslint-disable indent */
import styled from 'styled-components';

export default styled.button`
  text-decoration: none;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.dark};
  background: white;
  border: none;
  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.colors.primary.medium};
  }
`;
