// possible steps
export const SEND_CODE_STEP = 'SEND_CODE_STEP';
export const SEARCH_ACCOUNT_STEP = 'SEARCH_ACCOUNT_STEP';
export const VERIFY_CODE_STEP = 'VERIFY_CODE_STEP';
export const RESET_PASSWORD_STEP = 'RESET_PASSWORD_STEP';

// possible auth types
export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const RESET_PASSWORD = 'RESET_PASSWORD';
