/* eslint-disable no-unused-vars */
import React from 'react';
import { LoginForm, SignUpForm } from '..';
import { MultiTabContainer } from 'components';
import { useApiMessage } from 'hooks';
import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 370px;
`;

function AuthForms() {
  const [ApiMessage, setApiMessage] = useApiMessage();
  const isSignUpActive = React.useMemo(() => {
    if (sessionStorage.getItem('activeLoginTab') === 'sign-up') {
      return true;
    }
    return false;
  }, []);
  const tabs = [
    {
      component: <LoginForm setApiMessage={setApiMessage} />,
      name: 'Login',
      id: 'header-login',
      handleClick: function () {
        sessionStorage.setItem('activeLoginTab', 'login');
      },
      initActive: !isSignUpActive,
    },
    {
      component: <SignUpForm setApiMessage={setApiMessage} />,
      name: 'Sign Up',
      id: 'header-sign-up',
      handleClick: function () {
        sessionStorage.setItem('activeLoginTab', 'sign-up');
      },
      initActive: isSignUpActive,
    },
  ];
  return (
    <Wrapper>
      <ApiMessage />
      <MultiTabContainer tabs={tabs} />
    </Wrapper>
  );
}

export default AuthForms;
