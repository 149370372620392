import React from 'react';
import styled from 'styled-components';
import { Hero, Features } from './sections';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 100vh;
`;

function Home() {
  return (
    <Wrapper>
      <Hero />
      <Features />
    </Wrapper>
  );
}

export default Home;
