import React from 'react';

function Legal() {
  return (
    <section>
      <h1 style={{ margin: '32px 0' }}>Legal</h1>
      <div>
        <h2>General Information</h2>
        <p>Experience Warehouse</p>
        <p>Brooklyn, New York, United States</p>
        <p>
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>
        </p>
        <strong>Managing Director and Responsible:</strong>{' '}
        <span>Pavel Darii</span>
      </div>
      <br />
      <div>
        <h2>Disclaimer</h2>
        <br />
        <h3>Liability for Contents</h3>
        <p>
          We have taken great care in creating the content on our pages.
          However, we cannot guarantee the accuracy, completeness, or timeliness
          of the content. As a service provider, we are responsible for the
          content on our pages in accordance with the law. We are not required
          to monitor any transmitted or stored third-party information, or to
          investigate any circumstances that may indicate illegal activity.
          However, we are still required to remove or block the use of any such
          information in accordance with the law. We can only be held liable for
          such infringements once we are aware of a specific legal violation. If
          we become aware of any infringements, we will promptly remove the
          relevant content.
        </p>
        <br />
        <h3>Liability for Links</h3>
        <p>
          We provide links to third-party websites over which we have no
          control. Therefore, we cannot be held responsible for the content of
          these external websites. The providers or operators of these websites
          are always responsible for the content of the linked websites. We have
          checked the linked websites for any potential legal violations at the
          time of linking and did not find any. However, we cannot continuously
          monitor the linked websites for legal issues related to their content
          without specific evidence of a violation. If we become aware of any
          legal violations, we will promptly remove the relevant links.
        </p>
        <br />
        <h3>Copyright</h3>
        <p>
          The content on this website is protected by copyright, unless
          otherwise noted, and may not be distributed, modified, or copied in
          whole or in part without the prior written consent of
          ExperienceWarehouse. The images on this website may not be used
          without the prior written consent of ExperienceWarehouse. Some of the
          images on the website may also be subject to copyright by third
          parties. If you become aware of any copyright infringement, please
          inform us. We will promptly remove any such content if we become aware
          of any legal violations.
        </p>
        <br />
      </div>
    </section>
  );
}

export default Legal;
