import styled from 'styled-components';

export default styled.div`
  position: ${({ position }) => {
    return position || 'relative';
  }};
  overflow: ${({ overflow }) => {
    return overflow || 'hidden';
  }};
  min-width: ${({ minW }) => {
    return minW || '280px';
  }};
  max-width: ${({ maxW }) => {
    return maxW || '370px';
  }};
  width: ${({ w }) => {
    return w || '320px';
  }};
  height: ${({ h }) => {
    return h || 'auto';
  }};
  max-height: ${({ maxH }) => {
    return maxH || 'auto';
  }};
  min-height: ${({ minH }) => {
    return minH || 'auto';
  }};
  box-shadow: ${({ theme, boxShadow }) => {
    return (
      boxShadow ||
      theme.general.shadows.primary ||
      '0 11px 34px 0 rgb(0 0 0 / 20%)'
    );
  }};
  border: ${({ b }) => {
    return b || 'none';
  }};
  box-sizing: border-box;
  border-radius: ${({ theme, r }) => {
    return r || theme.general.borderRadius.medium || '8px';
  }};
  background: ${({ theme, bg }) => {
    return bg || theme.colors.general.white || 'inherit';
  }};
  display: flex;
  justify-content: ${({ justify }) => {
    return justify || 'left';
  }};
  align-items: ${({ align }) => {
    return align || 'top';
  }};
  flex-direction: ${({ direction }) => {
    return direction || 'column';
  }};
  padding: ${({ p }) => {
    return p || '0';
  }};
  margin: ${({ m }) => {
    return m || '0';
  }};
  gap: ${({ gap }) => {
    return gap || '0';
  }};
`;
