import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { INITIALIZE_USER_PROFILE } from 'store/actions';
import { setToken } from 'utils';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import WithLayout from './WithLayout';
import { userApi } from 'api';

// All layouts
import { Dashboard as DashboardLayout } from './layouts';
import { DashboardFullWidth } from './layouts/Dashboard';

// Landing Pages
import {
  Home as HomeView,
  NotFound,
  FAQ,
  About,
  Feedback,
  Terms,
  Privacy,
  Legal,
  Security,
  Subprocesses,
} from 'views/landingPages';

// Auth Pages
import { Authentication } from 'views/authPages';

//Prospect View Pages
import Portfolio from 'views/Portfolio';

// User Pages
import {
  Account as UserAccount,
  Dashboard as UserDashboard,
  Projects as UserProjects,
  Prospects as UserProspects,
  Prospect as UserProspect,
  Project as UserProject,
} from 'views/userPages';
import Pricing from 'views/landingPages/Pricing';

function RequireAuth({ children, redirectTo }) {
  const { token } = useSelector((state) => state).user;
  return token ? children : <Navigate to={redirectTo} />;
}

const Routes = () => {
  const { username, token } = useSelector((state) => state).user;
  // const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (token !== null) {
      setToken(token);
    }
  }, [token]);

  // React.useEffect(() => {
  //   console.log(user);
  // }, [user]);

  React.useEffect(() => {
    if (!username && token) {
      userApi
        .getInitialData(token)
        .then((res) => {
          dispatch({
            type: INITIALIZE_USER_PROFILE,
            user: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [username, token]);

  return (
    <ReactRoutes>
      <Route
        exact
        path="/"
        element={(() => (
          <WithLayout component={HomeView} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/login"
        element={(() => (
          <WithLayout component={HomeView} layout={DashboardFullWidth} />
        ))()}
      />
      <Route
        exact
        path="/sign-up"
        element={(() => (
          <WithLayout component={HomeView} layout={DashboardFullWidth} />
        ))()}
      />
      <Route
        exact
        path="/faq"
        element={(() => (
          <WithLayout component={FAQ} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/about"
        element={(() => (
          <WithLayout component={About} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/feedback"
        element={(() => (
          <WithLayout component={Feedback} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/legal"
        element={(() => (
          <WithLayout component={Legal} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/terms"
        element={(() => (
          <WithLayout component={Terms} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/privacy"
        element={(() => (
          <WithLayout component={Privacy} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/security"
        element={(() => (
          <WithLayout component={Security} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/subprocesses"
        element={(() => (
          <WithLayout component={Subprocesses} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/pricing"
        element={(() => (
          <WithLayout component={Pricing} layout={DashboardLayout} />
        ))()}
      />

      <Route
        exact
        path="/dashboard"
        element={(() => (
          <RequireAuth redirectTo="/">
            <WithLayout component={UserDashboard} layout={DashboardLayout} />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/profile"
        element={(() => (
          <RequireAuth redirectTo="/">
            <WithLayout component={UserAccount} layout={DashboardLayout} />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/projects"
        element={(() => (
          <RequireAuth redirectTo="/">
            <WithLayout component={UserProjects} layout={DashboardLayout} />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/project/*"
        element={(() => (
          <RequireAuth redirectTo="/">
            <WithLayout component={UserProject} layout={DashboardLayout} />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/prospects"
        element={(() => (
          <RequireAuth redirectTo="/">
            {' '}
            <WithLayout component={UserProspects} layout={DashboardLayout} />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/prospect/*"
        element={(() => (
          <RequireAuth redirectTo="/">
            <WithLayout component={UserProspect} layout={DashboardLayout} />
          </RequireAuth>
        ))()}
      />
      <Route
        exact
        path="/portfolio/:id"
        element={(() => (
          <Portfolio></Portfolio>
        ))()}
      />
      <Route
        exact
        path="/auth"
        element={(() => (
          <WithLayout component={Authentication} layout={DashboardLayout} />
        ))()}
      />
      <Route
        exact
        path="/:username/:id"
        element={(() => (
          <Portfolio></Portfolio>
        ))()}
      />
      <Route
        path="*"
        element={(() => (
          <WithLayout component={NotFound} layout={DashboardLayout} />
        ))()}
      />
    </ReactRoutes>
  );
};

RequireAuth.propTypes = {
  children: PropTypes.any,
  redirectTo: PropTypes.string,
};
export default Routes;
