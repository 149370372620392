import React from 'react';
import PropTypes from 'prop-types';

import { FiExternalLink } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

const Link = ({ link }) => {
  return (
    <>
      <div>
        {link ? (
          <a
            href={link}
            aria-label="url to an external resource icon"
            target="_blank"
            rel="noreferrer"
            data-for="go-to-url-tt"
            data-tip={link}
            data-delay-show="500"
            style={{ paddingTop: '4px' }}
          >
            <FiExternalLink color="black" size="24px" />
          </a>
        ) : (
          ''
        )}
        <ReactTooltip
          id="go-to-url-tt"
          place="right"
          type="dark"
          effect="solid"
          role="Url to the external site"
        />
      </div>
    </>
  );
};

Link.propTypes = {
  link: PropTypes.string,
};

export default Link;
