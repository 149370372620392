/* eslint-disable no-unused-vars */
import React from 'react';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useActivityPanel } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PROJECT_RESPONSIBILITIES } from 'store/actions';
import { projectApi } from 'api';
function SetResponsibilities({ project }) {
  const { addActivity, setLoading } = useActivityPanel();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state).user;
  function handleSubmit({ responsibilities }) {
    setLoading();
    projectApi
      .updateResponsibilities(token, project.id, responsibilities)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'Project responsibilities was set!',
        });
        dispatch({
          type: UPDATE_PROJECT_RESPONSIBILITIES,
          projectId: project.id,
          responsibilities,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="90px"
      inputComponent="textarea"
      initialValue={project.responsibilities}
      inputId="responsibilities"
      placeholder="Responsibilities"
      validationYup={Yup.string()}
      handleSubmit={handleSubmit}
    />
  );
}

SetResponsibilities.propTypes = {
  project: PropTypes.object,
};

export default SetResponsibilities;
