import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Content from './Content';
import { ActivityIndicator } from '../';
import { Box, ButtonSidebar, Container } from 'components/styles';
import { BsFillPersonFill, BsFillCaretUpFill } from 'react-icons/bs';
import { CHANGE_SIDEBAR_CHANGE } from 'store/actions';
import { ActivityPanel } from '..';

const Header = styled.header`
  background: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  padding: 4px 0;
`;

const Nav = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
`;

const TopBar = () => {
  const dispatch = useDispatch();
  const { user, layout } = useSelector((state) => state);
  const handleSidebarChange = () => {
    dispatch({ type: CHANGE_SIDEBAR_CHANGE });
  };
  return (
    <Header as={'header'}>
      <Container>
        <Nav>
          {user.token ? (
            <>
              <Box gap="4px">
                <ButtonSidebar onClick={handleSidebarChange}>
                  {layout.isSidebarActive ? (
                    <BsFillCaretUpFill size="30px" />
                  ) : (
                    <BsFillPersonFill size="30px" />
                  )}
                </ButtonSidebar>
                <ActivityIndicator />
              </Box>
              <ActivityPanel />
            </>
          ) : (
            ''
          )}
          <Content />
        </Nav>{' '}
      </Container>
    </Header>
  );
};

export default TopBar;
