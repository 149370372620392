import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'components/styles/Global';
import Routes from 'Routes';

import { Provider } from 'react-redux';
import { store } from 'store';
import { mainTheme } from 'theme';

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyles />
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
