import styled from 'styled-components';

export default styled.button`
  padding: 0;
  background: ${({ theme, bg }) => bg || theme.colors.general.white};
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  height: ${({ theme }) => theme.layout.barHeight} !important;
  width: ${({ theme }) => theme.layout.barHeight} !important;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-box &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.general.light};
  }
`;
