/* eslint-disable indent */

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    
    html {
      font-size: 100%;
    }

    @media (prefers-reduced-motion: no-preference) {
      html {
        scroll-behavior: smooth; 
      }
    }

    *,
    *::before,
    *::after,
    html {
      box-sizing: border-box | content-box;
    }

    :root {
      box-sizing: border-box;
      font-size: 14px;
    }

    ::selection { 
      color: white;
      background-color: ${({ theme }) => theme.colors.primary.medium};
    }

   body {
    margin:  0;
    background:transparent;
    color: ${({ theme }) => {
      return theme.colors.text.main;
    }};
    font-family: 'Poppins', sans-serif;
    font-size: 1.15em;
    text-rendering: geometricPrecision | optimizeLegibility | optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   }
   

   h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: bold;
    font-family: Lora, Montserat;
    line-height: 1.33337;
    letter-spacing: 0.01rem;
   }
  h1 { font-size: 1.8em;}
  h2 { font-size: 1.6em;}
  h3 { font-size: 1.4em;}
  h4 { font-size: 1.2em;}
  h5 { font-size: 1.1em;}
  h6 { font-size: 1.0em;}

   img {
    height: auto;
    max-width: 100%;
    user-select: none;
  }

  button {
    color: #fff; 
    height: fit-content;
    background: ${({ theme }) => theme.colors.primary.dark};
    border: 2px solid ${({ theme }) => theme.colors.primary.dark};
    border-radius: ${({ theme }) => theme.general.borderRadius.small};
    padding: 4px 8px;
    fon-size: 1.15rem;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1.15rem;
    line-height: 1.5rem;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
    width: max-content;
    word-break: normal;
    word-spacing: normal;
    outline: none;
  }
  button:hover, button:focus,  button:active {
    background: #fff;
    color: ${({ theme }) => theme.colors.primary.dark};
    
  }
 
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  button:hover:disabled, button:focus:disabled,  button:active:disabled {
    color: #fff; 
    background: ${({ theme }) => theme.colors.primary.dark};
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary.dark}
  }
  a:hover, a:focus, a:active {
    color: ${({ theme }) => theme.colors.primary.medium}
  }
  a, button {
    touch-action: manipulation; 
  }

  svg {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.primary.dark}
  }


  iframe, video {
    height: 100%;
    width: 100%
  }

   p {
    margin: 5px 0; 
    font-size: 1.15rem;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: 0.01rem;
   }
   input, textarea {
    font-size: 1.15rem;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: 0.01rem;
   }
   button {
    cursor:pointer

   }
   hr {
    height: 2px;
    background-color: ${({ theme }) => theme.colors.general.light};
    border: none;
   }
   .nav-active {
    color: ${({ theme }) => theme.colors.primary.medium}
   }
`;

export default GlobalStyles;
