import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CREATE_PROJECT } from 'store/actions';
import { useActivityPanel } from 'hooks';
import { BsPlus } from 'react-icons/bs';

import { projectApi } from 'api';
import { Card } from 'components/styles';

const AddProjectCard = () => {
  const { addActivity, setLoading } = useActivityPanel();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  let navigate = useNavigate();
  function handleAddProject() {
    setLoading();
    projectApi
      .createProject(user.token)
      .then((res) => {
        addActivity({
          type: 'success',
          message: 'New project was created',
        });
        dispatch({
          type: CREATE_PROJECT,
          projects: [...user.projects, res.data],
        });
        sessionStorage.setItem('editingProjectId', res.data.id);
        navigate('/project/new');
      })
      .catch((err) => {
        addActivity(err?.response?.data || { type: 'error' });
      });
  }
  return (
    <>
      <Card
        h="450px"
        justify="center"
        align="center"
        w="100%"
        as={'button'}
        onClick={handleAddProject}
      >
        <BsPlus size="70" color="grey" alt="plus icon" />
      </Card>
    </>
  );
};

export default AddProjectCard;
