/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { EditableProjectCard, MainCard } from 'components/ProjectCards';
import { Loading } from 'views/landingPages';

import {
  SetTitle as SetProjectTitle,
  SetResponsibilities as SetProjectResponsibilities,
  SetLink as SetProjectLink,
  SetSkills as SetProjectSkills,
  SetImages as SetProjectImages,
} from './forms';
import { useSelector } from 'react-redux';
import { CardEditorContainer, StepContainer } from 'components/styles';
import { Link } from 'react-router-dom';
const Wrapper = styled.section`
  display: flex;
  align-items: start;
  margin: 32px 0px;
  gap: 24px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const ContainerTwo = styled.div`
  margin-bottom: 32px;
  min-width: 370px;
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    min-width: fit-content;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 90vh;
`;

const Project = () => {
  const { projects } = useSelector((state) => state).user;
  const editingProjectId = sessionStorage.getItem('editingProjectId');
  const project = projects.filter(
    (project) => project?.id == editingProjectId,
  )[0];
  if (!project?.id) {
    return (
      <Container>
        <Loading />
        <h2>Sorry, the project is not found!</h2>
        <Link to="/projects">Back to projects?</Link>
      </Container>
    );
  }
  return (
    <>
      <Wrapper>
        <CardEditorContainer>
          <StepContainer>
            <h3>1. Set project title</h3>
            <SetProjectTitle project={project} />
          </StepContainer>
          <StepContainer>
            <h3>2. Set project link</h3>
            <SetProjectLink project={project} />
          </StepContainer>
          <StepContainer>
            <h3>3. Set your responsibilities </h3>
            <SetProjectResponsibilities project={project} />
          </StepContainer>
          <StepContainer>
            <h3>4. Set project skills</h3>
            <SetProjectSkills project={project} />
          </StepContainer>
          <StepContainer style={{ width: '100%' }}>
            <h3>5. Set project images</h3>
            <SetProjectImages project={project} />
          </StepContainer>
        </CardEditorContainer>
        <ContainerTwo>
          <EditableProjectCard id={project.id} active={project.active}>
            <MainCard
              title={project?.name}
              url={project?.url}
              skills={project?.skills}
              description={project?.description}
              thumbnail={project?.thumbnail}
              responsibilities={project?.responsibilities}
              images={project?.images}
            />
          </EditableProjectCard>
        </ContainerTwo>
      </Wrapper>
    </>
  );
};

export default Project;
