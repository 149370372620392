import axios from 'axios';

const api = {
  createProspect: (token) => {
    return axios.post(
      '/api/user/prospect/',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  deleteProspect: (token, prospectId) => {
    return axios.delete('/api/user/prospect/', {
      data: { prospectId },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  updateTitle: (token, prospectId, title) => {
    return axios.put(
      '/api/user/prospect/title',
      { prospectId, title },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateUrl: (token, prospectId, url) => {
    return axios.put(
      '/api/user/prospect/url',
      { prospectId, url },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateDescription: (token, prospectId, description) => {
    return axios.put(
      '/api/user/prospect/description',
      { prospectId, description },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  getSearchedSkills: function (token, searchedSkill) {
    return axios.get('/api/user/skills/' + searchedSkill, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    });
  },
  addExistingSkill(token, prospectId, skillId) {
    return axios.post(
      '/api/user/prospect/skill/existing',
      { prospectId, skillId },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  addNewSkill(token, prospectId, skillName) {
    return axios.post(
      '/api/user/prospect/skill/new',
      { prospectId, skillName },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  toggleProspectActivation(token, prospectId, isActive) {
    return axios.put(
      '/api/user/prospect/activation',
      { prospectId, isActive },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },

  deleteProspectSkill(token, prospectId, skillId) {
    return axios.delete('/api/user/prospect/skill/', {
      data: { prospectId, skillId },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
