/* eslint-disable no-unused-vars */
import React from 'react';
import { BsTwitter } from 'react-icons/bs';
import { GrLinkedinOption } from 'react-icons/gr';
import styled from 'styled-components';
const Wrapper = styled.section`
  width: 100%;
  max-width: 700px;
  margin: 32px auto;
  p {
    margin: 16px 0;
  }
`;

function About() {
  return (
    <div>
      <h2 style={{ margin: '32px 0' }}>About</h2>
      <Wrapper>
        <p>
          The idea for Experience Warehouse came from a group of professionals
          who were looking for a better way to document and share their work
          experience and accomplishments. We recognized that traditional resumes
          and LinkedIn profiles didn&apos;t always accurately reflect the scope
          of our skills and experience, and we wanted a way to showcase our full
          portfolio of work in a more comprehensive and personalized way.
        </p>
        <p>
          The development team at Experience Warehouse is dedicated to
          continuously improving the platform based on user feedback and the
          evolving needs of professionals. We are committed to maintaining the
          highest levels of privacy and security to ensure that users can trust
          the platform to safely and effectively document their professional
          progress.
        </p>
        <p>
          For any questions, please reach out to{' '}
          <a href="m&#97;il&#116;o&#58;s&#117;ppo%72t&#64;&#101;&#120;&#112;er%&#54;9ence%&#55;&#55;a%&#55;2e&#104;&#37;&#54;&#70;u%73e&#46;co%6D">
            support&#64;exper&#105;enc&#101;&#119;are&#104;ouse&#46;com
          </a>{' '}
          or to Pavel Darii{' '}
          <a
            href="https://www.linkedin.com/in/pavel-darii-0aa546185/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Linkedin Icon"
          >
            <GrLinkedinOption size="20px" />
          </a>{' '}
          <a
            href="https://twitter.com/darii_pavel"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter Icon"
          >
            <BsTwitter size="20px" />
          </a>
        </p>
      </Wrapper>
    </div>
  );
}

export default About;
