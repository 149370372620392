/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SendCodeForm,
  SearchAccountForm,
  VerifyCodeForm,
  ResetPasswordForm,
} from './';
import { useSelector } from 'react-redux';
import { Card, Box, Paragraph } from 'components/styles';
import { useApiMessage } from 'hooks';
import {
  SEND_CODE_STEP,
  SEARCH_ACCOUNT_STEP,
  VERIFY_CODE_STEP,
  RESET_PASSWORD_STEP,
} from './authConstants';

const SearchEmail = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state).user;
  const [authData, setAuthData] = useState(
    JSON.parse(sessionStorage.getItem('authData')),
  );
  const [ApiMessage, setApiMessage] = useApiMessage();

  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, [token]);

  useEffect(() => {
    if (
      (!authData?.email && !authData?.step) ||
      (!authData?.email && !authData?.type)
    ) {
      navigate('/');
    }
  }, []);

  const steps = {
    [SEND_CODE_STEP]: (
      <SendCodeForm
        setApiMessage={setApiMessage}
        authData={authData}
        setAuthData={setAuthData}
      />
    ),
    [SEARCH_ACCOUNT_STEP]: (
      <SearchAccountForm
        setApiMessage={setApiMessage}
        authData={authData}
        setAuthData={setAuthData}
      />
    ),
    [VERIFY_CODE_STEP]: (
      <VerifyCodeForm
        setApiMessage={setApiMessage}
        authData={authData}
        setAuthData={setAuthData}
      />
    ),
    [RESET_PASSWORD_STEP]: (
      <ResetPasswordForm
        setApiMessage={setApiMessage}
        authData={authData}
        setAuthData={setAuthData}
      />
    ),
  };
  return (
    <Box
      align="center"
      m="32px 0px"
      gap="8px"
      direction="column"
      h="100vh"
      w="100%"
    >
      {authData?.email ? (
        <Card w="100%">
          <img src={require('assets/img/profile/default.png')}></img>
          <Box m="8px">
            <Paragraph m="0">{authData.email}</Paragraph>
            <Paragraph m="0">Experience Warehouse User</Paragraph>
          </Box>
        </Card>
      ) : (
        ''
      )}

      <Card h="auto" w="100%">
        <Box m="8px">{steps[authData?.step]}</Box>
      </Card>
      <ApiMessage w="100%" m="0" />
    </Box>
  );
};

export default SearchEmail;
