import React from 'react';
import PropTypes from 'prop-types';

import { BsX } from 'react-icons/bs';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: ${({ theme }) => theme.general.borderRadius.small};
  font-size: 14px;
  font-weight: 300;
  background-color: ${({ theme }) => theme.colors.general.successLight};
`;

const SkillSection = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;

const DeleteButton = styled.button`
  border: none;
  outline: none;
  background-color: white;
  border-left: 1px solid ${({ theme }) => theme.colors.general.light};
  border-radius: 0 ${({ theme }) => theme.general.borderRadius.small}
    ${({ theme }) => theme.general.borderRadius.small} 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 4px 1px;

  svg:hover {
    fill: red;
  }
`;

const Editable = ({ name, handleDelete }) => {
  return (
    <Container>
      <SkillSection>{name}</SkillSection>
      <DeleteButton onClick={handleDelete}>
        <BsX />
      </DeleteButton>
    </Container>
  );
};

Editable.propTypes = {
  name: PropTypes.string,
  handleDelete: PropTypes.func,
};

export default Editable;
