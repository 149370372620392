import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_PROJECT_SKILL } from 'store/actions';
import { projectApi } from 'api';
import { useActivityPanel } from 'hooks';

import { EditableSkillTag } from 'components/Skill';
import { SetSkill } from '../';
import { Box } from 'components/styles';

const SetSkills = ({ project }) => {
  const { user } = useSelector((state) => state);
  const { addActivity, setLoading } = useActivityPanel();
  const dispatch = useDispatch();
  const deleteSkill = (token, projectId, skillId) => {
    setLoading();
    return projectApi
      .deleteProjectSkill(token, projectId, skillId)
      .then(() => {
        addActivity({
          type: 'success',
          message: 'A project skill was deleted!',
        });
        dispatch({
          type: DELETE_PROJECT_SKILL,
          projectId,
          skillId,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  };

  return (
    <Box gap="4px">
      {project?.skills.map(({ skill, id }) => {
        return (
          <EditableSkillTag
            key={id}
            name={skill}
            handleDelete={() => deleteSkill(user.token, project.id, id)}
          />
        );
      })}
      <SetSkill projectId={project.id} />
    </Box>
  );
};

SetSkills.propTypes = {
  project: PropTypes.object,
};

export default SetSkills;
