/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Box } from 'components/styles';
import { useTheme } from 'styled-components';
import { VscChromeClose } from 'react-icons/vsc';
import styled from 'styled-components';
const Button = styled.button`
  border: none;
  background: transparent !important;
  cursor: pointer;
  padding: 0 4px;
  height: 100%;
  :hover svg {
    fill: ${({ hC }) => hC};
  }
`;

const Children = styled.div`
  box-sizing: border-box;
  max-width: calc(100%);
  word-wrap: break-word;
`;
function ApiMessage({ type, title, clear, children }) {
  const theme = useTheme();
  return (
    <Card
      bg={theme.colors.general[type + 'Light']}
      boxShadow={'none'}
      direction="row"
      justify="space-between"
      w="100%"
    >
      <Box
        bl={'4px solid ' + theme.colors.general[type]}
        direction="column"
        p="4px 8px"
        maxW="calc(100% - 25px)"
      >
        <Box c={theme.colors.general[type]}>
          <strong>{title ? title : type}</strong>
        </Box>
        <Children>{children}</Children>
      </Box>
      <Box align="center">
        <Button onClick={clear} hC={theme.colors.general[type]}>
          <VscChromeClose />
        </Button>
      </Box>
    </Card>
  );
}

ApiMessage.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  title: PropTypes.string,
  clear: PropTypes.func,
};

export default ApiMessage;
