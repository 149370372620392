import React from 'react';
import { ApiMessage as ApiMessageNew } from 'components';
const initialState = {
  type: '',
  title: '',
  message: '',
};
function useApiMessage() {
  const [apiMessage, setApiMessage] = React.useState(initialState);
  const clear = () => setApiMessage(initialState);

  const ApiMessage = () => (
    <>
      {apiMessage?.type && (apiMessage?.title || apiMessage?.message) ? (
        <ApiMessageNew
          type={apiMessage?.type}
          title={apiMessage?.title}
          clear={clear}
        >
          {apiMessage?.message}
        </ApiMessageNew>
      ) : null}
    </>
  );
  return [ApiMessage, setApiMessage];
}

export default useApiMessage;
