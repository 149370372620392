import axios from 'axios';

const api = {
  login: function (email, password) {
    return axios.post('/api/user/login', { email, password });
  },
  signUp: function (email, password, full_name) {
    return axios.post('/api/user/signup', { email, password, full_name });
  },
  forgotPassword: function (email) {
    return axios.post('/api/user/forgot-password', { email });
  },
  setNewPassword(token, password) {
    return axios.post(
      '/api/user/new-password',
      {
        password,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },

  sendCode: function (email) {
    return axios.post('/api/user/send-code', { email });
  },
  resetPasswordVerifyCode: function (email, code) {
    return axios.post('/api/user/new-password/verify-code', {
      email,
      code,
    });
  },
  signUpVerifyCode: function (email, code) {
    return axios.post('/api/user/sign-up-verify-code', { email, code });
  },
  getInitialData: function (token) {
    return axios.get('/api/user/', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  },
  updateName: function (token, full_name) {
    return axios.put(
      '/api/user/fullName',
      { full_name },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateJob: function (token, job) {
    return axios.put(
      '/api/user/job',
      { job },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  updateAbout: function (token, about) {
    return axios.put(
      '/api/user/about',
      { about },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
  },
  uploadImages: function (token, formData) {
    return axios.post('/api/user/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    });
  },
  uploadImage: function (token, formData) {
    return axios.post('/api/user/image-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default api;
