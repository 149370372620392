/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { REMOVE_LIVE_ACTIVITY, REMOVE_LIVE_ACTIVITIES } from 'store/actions';
import { Button, Box, Card } from 'components/styles';
import { ApiMessage as ApiMessageNew } from 'components';
import { ACTIVITY_PANEL } from 'utils/topBarStates';

const PanelContainer = styled(Card)`
  height: auto !important;
  max-width: 370px;
  position: absolute;
  width: ${({ isSidebarActive }) =>
    isSidebarActive ? 'calc(100% - 44px)' : '100%'};
  top: 44px;
  left: ${({ isSidebarActive }) => (isSidebarActive ? '44px' : '0')};
  z-index: 1;
`;

const PanelButton = styled(Button)`
  box-sizing: border-box;
  padding: 4px 16px;
  min-width: 40%;
  text-align: center;
  border: none;
  color: ${({ theme }) => {
    return theme.colors.primary.dark;
  }};
  background: ${({ theme, active }) => {
    return active ? 'white' : theme.colors.general.light;
  }};
  :hover,
  :focus,
  :active {
    background: ${({ theme, active }) => {
      return active ? 'white' : theme.colors.general.light;
    }};
  }
`;

const PanelBody = styled(Box)`
  padding: 8px;
  flex-direction: column;
  gap: 8px;
  overflow: auto !important;
  width: 100%;
`;

const ACTIVITY_TAB = 'ACTIVITY_TAB';
const NOTIFICATIONS_TAB = 'NOTIFICATIONS_TAB';

const ActivityPanel = () => {
  const [activeTab, setActiveTab] = useState(ACTIVITY_TAB);
  const dispatch = useDispatch();
  const { layout, activityPanel } = useSelector((state) => state);
  const handleActivityClear = (id) => {
    dispatch({ type: REMOVE_LIVE_ACTIVITY, activityId: id });
  };
  const handleActivitiesClear = (id) => {
    dispatch({ type: REMOVE_LIVE_ACTIVITIES });
  };

  return (
    <>
      {layout.active === ACTIVITY_PANEL ? (
        <PanelContainer isSidebarActive={layout.isSidebarActive}>
          <Box justify="space-around" m="8px">
            <PanelButton
              onClick={() => setActiveTab(ACTIVITY_TAB)}
              active={activeTab === ACTIVITY_TAB}
            >
              Activity
            </PanelButton>
            <PanelButton
              onClick={() => setActiveTab(NOTIFICATIONS_TAB)}
              active={activeTab === NOTIFICATIONS_TAB}
            >
              Notifications
            </PanelButton>
          </Box>
          <Box maxH="360px" width="100%" justify="center" overflow="auto">
            {activeTab === ACTIVITY_TAB ? (
              <PanelBody>
                {activityPanel.activities.length ? (
                  activityPanel.activities
                    .slice(0)
                    .reverse()
                    .map(({ type, title, message, id }, i) => {
                      return (
                        <ApiMessageNew
                          key={i}
                          type={type}
                          title={title}
                          clear={() => handleActivityClear(id)}
                        >
                          {message}
                        </ApiMessageNew>
                      );
                    })
                ) : (
                  <Box as="p" justify="center">
                    Hi, no activity recorded!
                  </Box>
                )}
              </PanelBody>
            ) : (
              <PanelBody>
                {activityPanel.notifications.length ? (
                  activityPanel.notifications.map(
                    ({ type, title, message, id }, i) => {
                      return (
                        <ApiMessageNew
                          key={i}
                          type={type}
                          title={title}
                          clear={() => handleActivityClear(id)}
                        >
                          {message}
                        </ApiMessageNew>
                      );
                    },
                  )
                ) : (
                  <Box as="p" justify="center">
                    Hi, no notifications at the moment!
                  </Box>
                )}
              </PanelBody>
            )}
          </Box>
          <Box justify="space-around" m=" 8px">
            {activityPanel.activities.length && activeTab === ACTIVITY_TAB ? (
              <PanelButton onClick={handleActivitiesClear}>
                Clear activity history
              </PanelButton>
            ) : (
              ''
            )}
            {activityPanel.notifications.length &&
            activeTab === NOTIFICATIONS_TAB ? (
              <PanelButton>Delete Notifications</PanelButton>
            ) : (
              ''
            )}
          </Box>
        </PanelContainer>
      ) : (
        ''
      )}
    </>
  );
};

ActivityPanel.propTypes = {
  test: PropTypes.string,
};

export default ActivityPanel;
