import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.general.gradients.primary};
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  max-width: 370px;
  box-sizing: content-box;
  position: relative;
  height: fit-content;
`;
const Inner = styled.div`
  margin: 2px;
  background: white;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
  padding: 8px;
  height: calc(100% - 4px);
`;

function Feature({ title, text }) {
  return (
    <Wrapper>
      <Inner>
        <h3>{title}</h3>
        <hr></hr>
        <p>{text}</p>
      </Inner>
    </Wrapper>
  );
}
Feature.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};
export default Feature;
