/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'components/styles';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LAYOUT_STATE, SET_PANEL_STATUS } from 'store/actions';
import {
  LOADING,
  SUCCESS,
  ERROR,
  WARNING,
  CLOSE,
  DEFAULT,
} from 'utils/apiIndicatorStatuses';
import { ACTIVITY_PANEL, CLOSED_ALL } from 'utils/topBarStates';
import { gradient } from 'components/styles/animations';
import { BsFillCaretUpFill } from 'react-icons/bs';
const IconSpan = styled.span`
  font-size: 20px;
  display: flex;
  align-items: center;
`;
const AIButton = styled.button`
  background-size: 400% 400% !important;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bg, theme }) => {
    return (
      bg ||
      'linear-gradient(-45deg,' +
        theme.colors.general.infoLight +
        ', ' +
        theme.colors.general.errorLight +
        ', ' +
        theme.colors.general.successLight +
        ', ' +
        theme.colors.general.warningLight +
        ')'
    );
  }};
  border: none;
  animation: ${gradient} 15s ease-in-out infinite;
  :hover,
  :focus {
    background: ${({ bg, theme }) => {
      return (
        bg ||
        'linear-gradient(-45deg,' +
          theme.colors.general.warningLight +
          ', ' +
          theme.colors.general.errorLight +
          ', ' +
          theme.colors.general.successLight +
          ', ' +
          theme.colors.general.infoLight +
          ')'
      );
    }};
    animation: ${gradient} 3s ease-in-out infinite;
  }
`;
const ActivityIndicator = () => {
  const dispatch = useDispatch();
  const { layout, activityPanel } = useSelector((state) => state);
  const theme = useTheme();
  const handleClick = useCallback(() => {
    if (layout.active === ACTIVITY_PANEL) {
      dispatch({
        type: SET_PANEL_STATUS,
        status: DEFAULT,
      });
      dispatch({ type: SET_LAYOUT_STATE, active: CLOSED_ALL });
    } else {
      dispatch({ type: SET_LAYOUT_STATE, active: ACTIVITY_PANEL });
      dispatch({
        type: SET_PANEL_STATUS,
        status: CLOSE,
      });
    }
  }, [layout]);

  useEffect(() => {
    dispatch({
      type: SET_PANEL_STATUS,
      status:
        activityPanel.activities[activityPanel.activities.length - 1]?.type ||
        DEFAULT,
    });
    setTimeout(function () {
      dispatch({
        type: SET_PANEL_STATUS,
        status: DEFAULT,
      });
    }, 3000);
  }, [activityPanel.activities]);

  const displayStatus = useMemo(() => {
    let status = activityPanel.status;
    switch (status) {
      case LOADING:
        return <Loader size="30px" />;
      case SUCCESS:
        return '🥳';
      case ERROR:
        return '🤔';
      case WARNING:
        return '⚠️';
      case CLOSE:
        if (layout.active === ACTIVITY_PANEL) {
          return <BsFillCaretUpFill size="30px" />;
        } else {
          return;
        }
      default:
        return '';
    }
  }, [activityPanel.status, layout.active]);

  return (
    <AIButton
      bg={theme.colors.general[activityPanel.status + 'Light']}
      onClick={handleClick}
    >
      <IconSpan> {displayStatus}</IconSpan>
    </AIButton>
  );
};

ActivityIndicator.propTypes = {
  test: PropTypes.string,
};

export default ActivityIndicator;
