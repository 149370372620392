import React from 'react';
import PropTypes from 'prop-types';
import OneInputForm from 'components/forms/templates/OneInputForm/OneInputForm';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PROSPECT_LINK } from 'store/actions';
import { useActivityPanel } from 'hooks';
import { prospectApi } from 'api';
import * as Yup from 'yup';

const SetLink = ({ prospect }) => {
  const dispatch = useDispatch();
  const { addActivity, setLoading } = useActivityPanel();
  const { token } = useSelector((state) => state).user;
  function handleSubmit(values) {
    setLoading();
    prospectApi
      .updateUrl(token, prospect.id, values.url)
      .then(() => {
        dispatch({
          type: UPDATE_PROSPECT_LINK,
          prospectId: prospect.id,
          url: values.url,
        });
        addActivity({
          type: 'success',
          message: 'Prospect url was set to ' + values.url,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <OneInputForm
      inputHeight="28px"
      inputComponent="input"
      initialValue={prospect.url}
      inputId="url"
      placeholder="prospect related link, if any"
      validationYup={Yup.string().url('Invalid URL')}
      handleSubmit={handleSubmit}
    />
  );
};

SetLink.propTypes = {
  prospect: PropTypes.object,
};

export default SetLink;
