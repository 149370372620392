/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import EditorWrapper from 'components/EditorWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DELETE_PROSPECT, TOGGLE_PROSPECT } from 'store/actions';
import { useActivityPanel } from 'hooks';
import { useTheme } from 'styled-components';
import { prospectApi } from 'api';
import { FaPowerOff } from 'react-icons/fa';
import { CgTrashEmpty } from 'react-icons/cg';
import { MdModeEdit } from 'react-icons/md';

function Editable({ id, active, children }) {
  const { addActivity, setLoading } = useActivityPanel();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state).user;
  let navigate = useNavigate();

  const handleActivation = useCallback(() => {
    setLoading();
    prospectApi
      .toggleProspectActivation(token, id, !active)
      .then(() => {
        addActivity({
          type: 'success',
          message: !active
            ? 'A prospect was activated'
            : 'A prospect was deactivated',
        });
        dispatch({
          type: TOGGLE_PROSPECT,
          prospectId: id,
          isActive: !active,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }, [token, id, active]);

  const handleEdit = useCallback(() => {
    sessionStorage.setItem('editingProspectId', id);
    navigate('/prospect/edit');
  }, [id]);

  const handleDelete = useCallback(() => {
    setLoading();
    prospectApi
      .deleteProspect(token, id)
      .then((res) => {
        addActivity({
          type: 'success',
          message: 'A prospect was deleted',
        });
        dispatch({
          type: DELETE_PROSPECT,
          prospectId: res.data,
        });
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }, [token, id]);

  const config = useMemo(() => {
    return {
      style: {
        maxWidth: '440px',
        background: active
          ? theme.colors.general.successLight
          : theme.colors.general.greyLight,
        topBar: {
          width: '100px',
          height: '30px',
        },
      },
      tools: [
        {
          name: 'Activator',
          icon: FaPowerOff,
          action: handleActivation,
          style: {
            icon: {
              color: active
                ? theme.colors.general.success
                : theme.colors.general.grey,
              size: '20px',
              margin: '0 0 -4px',
            },
          },
        },
        {
          name: 'Edit',
          icon: MdModeEdit,
          action: handleEdit,
          style: {
            icon: {
              color: theme.colors.general.grey,
              size: '22px',
              margin: '0 0 -3px',
            },
          },
        },
        {
          name: 'Delete',
          icon: CgTrashEmpty,
          action: handleDelete,
          style: {
            icon: {
              color: theme.colors.general.grey,
              size: '22px',
              margin: '0 0 -3px',
            },
          },
        },
      ],
    };
  }, [active, handleActivation, handleEdit, handleDelete]);

  return <EditorWrapper config={config}>{children}</EditorWrapper>;
}

Editable.propTypes = {
  id: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
};

export default Editable;
