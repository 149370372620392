/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { prospectApi } from 'api';

import { useDispatch, useSelector } from 'react-redux';
import { ADD_PROSPECT_SKILL } from 'store/actions';
import { useActivityPanel } from 'hooks';
import CreatableSelect from 'react-select/creatable';
import './SetSkill.css';
const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1 px solid red !important',
  }),
  option: (styles, { data, isDisabled, isFocused }) => {
    return {
      ...styles,
      color: 'black',
      padding: '4px',
      fontWeight: '300',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: data && isFocused ? 'rgba(0,0,0,0.05)' : 'white',
    };
  },
};
function SetSkill({ prospectId }) {
  const [options, setOptions] = useState([]);
  const { addActivity, setLoading } = useActivityPanel();
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  function getListOfSkills(value) {
    prospectApi
      .getSearchedSkills(user.token, value)
      .then((res) => {
        setOptions(res.data.skills);
      })
      .catch(() => {
        return;
      });
  }

  function handleInputChange(value) {
    if (value.length > 2) {
      getListOfSkills(value);
    }
  }
  function handleChange(e) {
    if (e?.__isNew__) {
      setLoading();
      prospectApi
        .addNewSkill(user.token, prospectId, e.value)
        .then((res) => {
          addActivity({
            type: 'success',
            message: (
              <div>
                <strong>{e.label}</strong> skill was added to the prospect!
              </div>
            ),
          });
          dispatch({
            type: ADD_PROSPECT_SKILL,
            skill: e.label,
            id: res.data.skill_id,
            prospectId,
          });
        })
        .catch(() => {
          addActivity({
            type: 'error',
            message:
              e.label +
              ' skill is already added to the prospect or there been an issue saving it. Please refresh the page or try again!',
          });
        });
    } else if (e?.label && e?.value) {
      setLoading();
      prospectApi
        .addExistingSkill(user.token, prospectId, e.value)
        .then((res) => {
          addActivity({
            type: 'success',
            message: (
              <div>
                <strong>{e.label}</strong> skill was added to the prospect!
              </div>
            ),
          });
          dispatch({
            type: ADD_PROSPECT_SKILL,
            skill: e.label,
            id: res.data.skill_id,
            prospectId,
          });
        })
        .catch((err) => {
          addActivity({
            type: 'error',
            message:
              e.label +
              ' skill is already added to the prospect or there been an issue saving it. Please refresh the page or try again!',
          });
        });
    }
  }

  useEffect(() => {
    setOptions([]);
  }, []);

  return (
    <CreatableSelect
      className="creatable-select"
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      placeholder="Add Skill"
      styles={colorStyles}
      isClearable
    />
  );
}
SetSkill.propTypes = {
  prospectId: PropTypes.any,
};

export default SetSkill;
