import React from 'react';
import { Link } from 'react-router-dom';

function Pricing() {
  return (
    <div>
      <br />
      <h1>Pricing</h1>
      <br />
      <p>
        Currently, Experience Warehouse offers only a free tier, so all of our
        services are free to use. We welcome your{' '}
        <Link to="/feedback">feedback</Link> at any time.
      </p>
    </div>
  );
}

export default Pricing;
