/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Card } from 'components/styles';

const Img = styled.img`
  height: 100px;
  width: auto;
  max-width: 150px;
  background: ${({ theme }) => theme.colors.general.light};
  object-fit: cover;
  border-radius: ${({ theme }) => theme.general.borderRadius.medium};
`;
const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-wrap: nowrap;
  gap: 16px;
  .job-header {
    font-size: 20px;
  }
  @media (max-width: ${({ theme }) => theme.screenSizes.xs}) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  } ;
`;

function UserCard({ full_name, job, about, profile_image }) {
  return (
    <Card maxW="750px" w="100%" h="auto" overflow="auto">
      <Wrapper>
        <Box minW="fit-content">
          {profile_image ? (
            <Img src={profile_image} alt="card placeholder" />
          ) : (
            <Img src={'/'} alt="card placeholder" />
          )}
        </Box>
        <Box direction="column" m="-4px 0 0">
          <h2>{full_name} </h2>
          <h2 className="job-header"> {job}</h2>
          <p>{about}</p>
        </Box>
      </Wrapper>
    </Card>
  );
}

UserCard.propTypes = {
  full_name: PropTypes.string,
  job: PropTypes.string,
  about: PropTypes.string,
  profile_image: PropTypes.string,
};

export default UserCard;
