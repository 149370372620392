import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`;

const Responsibilities = ({ responsibilities }) => {
  return <Paragraph>{responsibilities ? responsibilities : ''}</Paragraph>;
};

Responsibilities.propTypes = {
  responsibilities: PropTypes.string,
};

export default Responsibilities;
