import React from 'react';
import { MainProspectCard } from 'components/ProspectCards';
import { useSelector } from 'react-redux';
import { AddProspectCard } from './components';
import { EditableProspectCard } from 'components/ProspectCards';
import { EditorWrapper } from 'components';
import { useTheme } from 'styled-components';
import { CardsContainer } from 'components/styles';

const Prospects = () => {
  const theme = useTheme();
  const { prospects } = useSelector((state) => state).user;
  const config = {
    style: {
      maxWidth: '440px',
      background: theme.colors.general.greyLight,
      topBar: {
        width: '100px',
        height: '30px',
      },
    },
    tools: [],
  };
  return (
    <>
      <h2 style={{ margin: '32px 0' }}>Prospects</h2>
      <CardsContainer childW="440px" fwBreak={theme.screenSizes.md}>
        <EditorWrapper config={config}>
          <AddProspectCard />
        </EditorWrapper>
        {prospects.map(
          (
            { active, id, name, description, url, visited, skills, unique_id },
            i,
          ) => {
            return (
              <EditableProspectCard key={i} id={id} active={active}>
                <MainProspectCard
                  name={name}
                  description={description}
                  url={url}
                  skills={skills}
                  visited={visited}
                  unique_id={unique_id}
                  active={active}
                />
              </EditableProspectCard>
            );
          },
        )}
        {[1, 2, 3].map((n) => {
          return <div key={n}></div>;
        })}
      </CardsContainer>
    </>
  );
};

export default Prospects;
