import { keyframes } from 'styled-components';
export const showSouthEast = keyframes`
0% {
  top: -250px;
  left: -180px;
  -webkit-transform: scale(0.05);
  -moz-transform: scale(0.05);
  -o-transform: scale(0.05);
  transform: scale(0.05);
  border-radius: 50px;
}
75%{
  top: 0;
  left:0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  z-index:1;
}
100% {
  top: 0;
  left:0;
}
`;
