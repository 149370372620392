import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Container } from 'components/styles';
import { TopBar, Footer, Sidebar } from '.';

const Dashboard = ({ children }) => {
  const { user, layout } = useSelector((state) => state);
  return (
    <>
      <TopBar />
      <Box minH="calc(100vh - 48px)">
        <Container>
          <Box w="100%" gap="4px" wrap="nowrap">
            {user.token && layout.isSidebarActive ? <Sidebar /> : ''}
            <Box maxW="100%" w="100%">
              {children}
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.object,
};

export default Dashboard;
