import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Container, Box } from 'components/styles';
import { NavLink } from 'react-router-dom';
const FooterLink = styled(NavLink)`
  color: white;
`;
const Footer = () => {
  const theme = useTheme();
  return (
    <>
      <Box as={'footer'} bg={theme.colors.general.black}>
        <Container>
          <Box c="white" p="4px 0" gap="32px">
            <Box direction="column">
              <FooterLink to="/">Home</FooterLink>
              <FooterLink to="/about">About</FooterLink>
            </Box>
            <Box direction="column">
              <FooterLink to="/pricing">Pricing</FooterLink>
              <FooterLink to="/feedback">Feedback</FooterLink>
            </Box>
            <Box direction="column">
              <FooterLink to="/legal">Legal</FooterLink>
              <FooterLink to="/terms">Terms</FooterLink>
              <FooterLink to="/privacy">Privacy</FooterLink>
            </Box>
          </Box>
        </Container>
        <Box bg="black" w="100%">
          <Container>
            <Box c="white" p="4px 0" justify="center" gap="16px">
              <FooterLink to="#">
                Copyright © 2023 ExperienceWarehouse
              </FooterLink>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
