import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Widget } from 'components';
import { useStatistics } from 'hooks';
import { MdAddBox } from 'react-icons/md';
import { Stacked } from 'components/charts';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  svg {
    color: green;
    margin-bottom: -3px;
    margin-right: -2px;
  }
`;

function ProjectsItem({ name, number }) {
  return (
    <span>
      {name}: <strong>{number}</strong>
    </span>
  );
}

function Projects({ link }) {
  const { projectsStatistics, constants } = useStatistics();
  const { COMPLETE, OPTIONAL } = constants;
  const { total, totalActive, totalImages, skills } = projectsStatistics;

  const items = useMemo(() => {
    return [
      { name: 'Total Images', number: totalImages },
      {
        name: 'Total Distinct Skills',
        number: Object.keys(skills).length,
      },
    ];
  }, [total, totalActive, totalImages, skills]);
  const projectsData = useMemo(() => {
    return [
      {
        name: 'Projects',
        active: totalActive || 0,
        inactive: total - totalActive || 0,
      },
    ];
  }, [total, totalActive]);
  return (
    <Widget
      title="Projects"
      link={link}
      score={totalActive > 0 ? COMPLETE : OPTIONAL}
      icon={<MdAddBox size="30px" />}
    >
      <Body>
        <Stacked data={projectsData} />
        {items.map(({ name, number }, i) => {
          return <ProjectsItem key={i} name={name} number={number} />;
        })}
      </Body>
    </Widget>
  );
}
ProjectsItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  number: PropTypes.number,
};

Projects.propTypes = {
  link: PropTypes.string,
};
export default Projects;
