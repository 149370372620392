/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Card } from 'components/styles';
import { FiExternalLink } from 'react-icons/fi';
import { TypeOneSkillTag } from 'components/Skill';
import { useSelector } from 'react-redux';

const ActionPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 120px;
  width: 120px;
  gap: 8px;
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    flex-direction: row;
    width: 100%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 4px;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.screenSizes.sm}) {
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
  }
`;

const ContainerOne = styled.div`
  padding: 8px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Header = styled.h3`
  span {
    margin-top: -4px;
  }
`;

function ProspectCard({
  name,
  active,
  visited,
  description,
  url,
  skills,
  unique_id,
}) {
  const { full_name } = useSelector((state) => state).user;

  const [clipboardText, setClipboardText] = useState('Copy link');
  const username = useMemo(() => {
    return full_name.toLowerCase().split(' ').join('');
  }, [full_name]);

  const handleUrlCopy = useCallback(() => {
    let url = `https://www.experiencewarehouse.com/${username}/${unique_id}`;
    navigator.clipboard.writeText(url);
    setClipboardText('Copied');
    setTimeout(function () {
      setClipboardText('Copy link');
    }, 3000);
  }, [unique_id, username]);

  return (
    <Card w="100%" h="250px" maxW="440px">
      <InnerContainer>
        <ContainerOne>
          <Header>
            <Box gap="4px">
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  <FiExternalLink size="24px" />
                </a>
              ) : (
                ''
              )}
              <span>{name}</span>
            </Box>
          </Header>
          <Box gap="4px">
            {skills.map(({ skill }, i) => (
              <TypeOneSkillTag key={i} name={skill} />
            ))}
          </Box>
          <p>{description}</p>
        </ContainerOne>
        <ActionPanel>
          <Box direction="column" align="center" justify="left">
            <span>{active ? 'Active' : 'Inactive'}</span>
            <button onClick={handleUrlCopy} disabled={active ? false : true}>
              {clipboardText}
            </button>
            <Link to={`/${username}/${unique_id}`}>Preview</Link>
          </Box>{' '}
          <strong>{unique_id}</strong>
        </ActionPanel>
      </InnerContainer>
    </Card>
  );
}

ProspectCard.propTypes = {
  name: PropTypes.string,
  visited: PropTypes.bool,
  active: PropTypes.bool,
  description: PropTypes.string,
  url: PropTypes.string,
  skills: PropTypes.array,
  unique_id: PropTypes.string,
};

export default ProspectCard;
