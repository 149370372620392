import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CREATE_PROSPECT } from 'store/actions';
import { useActivityPanel } from 'hooks';
import { BsPlus } from 'react-icons/bs';

import { prospectApi } from 'api';
import { Card } from 'components/styles';

const AddProspectCard = () => {
  const { addActivity, setLoading } = useActivityPanel();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  let navigate = useNavigate();
  function handleAddProspect() {
    setLoading();
    prospectApi
      .createProspect(user.token)
      .then((res) => {
        addActivity({
          type: 'success',
          message: 'New prospect was created',
        });
        dispatch({
          type: CREATE_PROSPECT,
          prospects: [...user.prospects, res.data],
        });
        sessionStorage.setItem('editingProspectId', res.data.id);
        navigate('/prospect/new');
      })
      .catch((err) => {
        addActivity(err?.response?.data);
      });
  }
  return (
    <>
      <Card
        w="100%"
        h="250px"
        maxW="auto"
        justify="center"
        align="center"
        as={'button'}
        onClick={handleAddProspect}
      >
        <BsPlus size="70" color="grey" alt="plus icon" />
      </Card>
    </>
  );
};

export default AddProspectCard;
